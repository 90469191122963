import React from 'react';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import {
  updateElectricityPriceWithChanges,
  updateGasAndElectricityPriceWithChanges,
  updateGasPriceWithChanges
} from 'js/components/Ectoplanner/EctoplannerModelUtils';
import {
  ModelDefinition,
  ModelDynamicStringProperty,
  ModelBoolFunctionRootProperty,
  ModelFormLineType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import copData from './Calculation/data/copData';
import calcBes from './Calculation/utils/calcBes';

import {
  formatNumberUnitNode,
  isNullOrWhitespace
} from 'ecto-common/lib/utils/stringUtils';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import {
  EctoplannerForm,
  EctoplannerFormBuilding,
  EctoplannerFormBuildingParams,
  EctoplannerGridTemperatureMethodOptions,
  EctoplannerSolarAreaDefinition
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import {
  ModelFormSectionStyle,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import moment from 'moment';
import { mainState } from 'js/components/Ectoplanner/EctoplannerCalculationSettings';
import colors from 'ecto-common/lib/styles/variables/colors';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import EctoplannerNumericalArrayEditor from 'js/components/Ectoplanner/EctoplannerNumericalArrayEditor';
import {
  EctoplannerDemandType,
  EctoplannerDemandTypes,
  EctoplannerFormEnvironment,
  EctoplannerProfileTypes,
  PlotTypes
} from 'js/components/Ectoplanner/EctoplannerTypes';
import {
  ValueOfCollection,
  makeKey,
  makeKey3
} from 'ecto-common/lib/utils/typescriptUtils';
import EctoplannerSolarAreaEditor from 'js/components/Ectoplanner/EctoplannerSolarAreaEditor';
import { EctoplannerDemandGraphs } from './EctoplannerDemandGraphs';
import { EctoplannerProfileEditor } from 'js/components/Ectoplanner/EctoplannerProfileEditor';
import {
  HorizontalLabelValueWeights,
  KeyValueGeneric
} from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';

import ImageDistrict_heating_2 from './assets/DistrictHeating.svg';
import ImageDistrict_cooling_2 from './assets/DistrictCooling.svg';
import ImageAmbient_heat from './assets/HeatingDemand.svg';
import ImageAmbient_cold from './assets/CoolingDemand.svg';
import ImageHeat_pump_reversible from './assets/AirSourceHeatpump.svg';
import ImageGeothermal from './assets/Geothermal.svg';
import ImageHeat_pump_heat_only from './assets/HP.svg';
import ImageAquifer_storage from './assets/Aquifer.svg';
import ImageSTC from './assets/SolarSTC.svg';
import ImagePV from './assets/SolarPV.svg';

import ImageEH from './assets/ElectricHeater.svg';
import ImageCC from './assets/CompressionChiller.svg';
import ImageAC from './assets/AbsorptionChillerIcon.svg';
import ImageCHP_ICE from './assets/CHP.svg';
import ImageBOI from './assets/GasBoiler.svg';
import ImagePassiveBalancingUnit from './assets/PassiveBalancingUnit.svg';
import ImageBAT from './assets/Battery.svg';
import ImageHeatStorage from './assets/HeatStorage.svg';
import ImageColdStorage from './assets/ColdStorage.svg';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';

const ScenarioParametersCsv = `DE_Reference;0.071;2.2;0.091;-0.4;0.036;1.9;0.016;0;0.04;0.031;19
DE_Electrification;0.071;3;0.091;-0.3;0.036;3.5;0.016;6.6;0.03;0.031;19
DE_Technology_Mix;0.071;2.8;0.091;0.2;0.036;3.5;0.016;0.3;0.04;0.031;19
SWE_Reference;0.032;1.8;0.069;0;0.03;0.9;0.0263;0;0.0261;0.0289;25
SWE_Electrification;0.032;2.9;0.0687;0;0.03;2.6;0.0263;0;0.0261;0.0289;25`;

const eurKwhNumberSettings = {
  modelType: ModelType.NUMBER,
  min: 0,
  max: 0.5,
  step: 0.01,
  unit: T.ectoplanner.units.eurkwh
};

const eurKwhLargeNumberSettings = {
  modelType: ModelType.NUMBER,
  min: 0,
  max: 10,
  step: 0.01,
  unit: T.ectoplanner.units.eurkwh
};

const percentANumberSettings = {
  modelType: ModelType.NUMBER,
  min: -30,
  max: 30,
  step: 1,
  unit: T.ectoplanner.units.percenta
};

const kwhKwhNumberSettings = {
  modelType: ModelType.NUMBER,
  min: 0,
  max: 10,
  step: 0.01,
  unit: T.ectoplanner.units.kwhkwh
};

const numberPercentOptions = {
  modelType: ModelType.NUMBER,
  min: 1,
  max: 100,
  step: 1
};

const numberPercentWithUnitOptions = {
  ...numberPercentOptions,
  unit: T.ectoplanner.units.percent
};

const copNumberOptions = {
  modelType: ModelType.NUMBER,
  min: 1,
  max: 10,
  step: 0.1
};

const roundTwoDecimals = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

const monthsConst = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const ectoplannerCityDataKeys: (keyof EctoplannerFormBuildingParams)[] =
  [
    'annDemSpecHeat',
    'annDemSpecCool',
    'annDemSpecDhw',
    'annDemSpecProcessCool',
    'annDemSpecPlugLoads'
  ];

type EctoplannerBoolFunction = ModelBoolFunctionRootProperty<
  EctoplannerForm,
  EctoplannerFormEnvironment
>;

type EctoplannerFormLineType = Omit<
  ModelFormLineType<EctoplannerForm, EctoplannerFormEnvironment>,
  'models' | 'lines'
> & {
  models?: EctoplannerModelDefinition[];
  lines?: EctoplannerFormLineType[];
};

type BaseEctoplannerSectionType = Omit<
  ModelFormSectionType<EctoplannerForm, EctoplannerFormEnvironment>,
  'models' | 'sections'
>;

export type EctoplannerSectionType = BaseEctoplannerSectionType & {
  models?: EctoplannerModelDefinition[];
  lines?: EctoplannerFormLineType[];
  sections?: EctoplannerSectionType[];
};

type EctoplannerModelDefinition = ModelDefinition<
  EctoplannerForm,
  EctoplannerFormEnvironment
> & {
  optional?: boolean;
};

const copOptions = [
  {
    label: T.ectoplanner.form.building.params.copoptions.const,
    value: 'const'
  },
  {
    label: T.ectoplanner.form.building.params.copoptions.productdata,
    value: 'productData'
  },
  {
    label: T.ectoplanner.form.building.params.copoptions.carnot,
    value: 'carnot'
  }
];

const copOptionsWithUpload = [
  ...copOptions,
  {
    label: T.ectoplanner.form.building.params.copoptions.upload,
    value: 'upload'
  }
];

const airSourceHeatPumpOptions = Object.values(copData.getHpData('air')).map(
  (x) => ({
    label: x.name,
    value: x.id
  })
);

const heatPumpOptions = Object.values(copData.getHpData('water')).map((x) => ({
  label: x.name,
  value: x.id
}));

const gridTMethodOptions = [
  {
    label: T.ectoplanner.form.network.gridtmethod.constant,
    value: EctoplannerGridTemperatureMethodOptions.Constant
  },
  {
    label: T.ectoplanner.form.network.gridtmethod.userupload,
    value: EctoplannerGridTemperatureMethodOptions.UserUpload
  },
  {
    label: T.ectoplanner.form.network.gridtmethod.suggestion,
    value: EctoplannerGridTemperatureMethodOptions.Suggestion
  }
];

type ScenarioType = {
  price_ex_el: number;
  price_growth_el: number;
  price_grid_el: number;
  grid_growth_el: number;

  price_ex_gas: number;
  price_growth_gas: number;
  price_grid_gas: number;
  grid_growth_gas: number;

  env_surcharge: number;
  oth_surcharge: number;
  tax_rate: number;
};

const EconomicScenarios: Record<string, ScenarioType> = _.reduce(
  ScenarioParametersCsv.split('\n'),
  (dict, line) => {
    const elems = line.split(';');
    const scenario = elems[0];

    dict[scenario] = {
      price_ex_el: parseFloat(elems[1]),
      price_growth_el: parseFloat(elems[2]),
      price_grid_el: parseFloat(elems[3]),
      grid_growth_el: parseFloat(elems[4]),

      price_ex_gas: parseFloat(elems[5]),
      price_growth_gas: parseFloat(elems[6]),
      price_grid_gas: parseFloat(elems[7]),
      grid_growth_gas: parseFloat(elems[8]),

      env_surcharge: parseFloat(elems[9]),
      oth_surcharge: parseFloat(elems[10]),
      tax_rate: parseFloat(elems[11])
    };

    return dict;
  },
  {} as Record<string, ScenarioType>
);

const buildingHorizontalWeights: HorizontalLabelValueWeights = [4, 5];
export const ectoplannerHorizontalWeights: HorizontalLabelValueWeights = [4, 3];
export const fileHorizontalWeights: HorizontalLabelValueWeights = [1, 1];

type MaxCapPrefixType =
  | 'revHeatPumpBu'
  | 'revHeatPumpBu2'
  | 'absorptionChillerBu'
  | 'batteryBu'
  | 'cHPBu'
  | 'coldStorageBu'
  | 'compressionChillerBu'
  | 'electricalHeaterBu'
  | 'gSHeatPumpBu'
  | 'gasBoilerBu'
  | 'heatStorageBu'
  | 'simpleHeatPumpBu';

const maxCapModels = (
  prefix: MaxCapPrefixType,
  visible: EctoplannerBoolFunction,
  unit: React.ReactNode = T.ectoplanner.units.kwth
): EctoplannerModelDefinition[] => {
  return [
    {
      key: (input) => input[prefix].min_cap,
      visible,
      modelType: ModelType.NUMBER,
      min: 0,
      step: 10,
      unit,
      label: T.ectoplanner.form.shared.mincap.label
    },
    {
      key: (input) => input[prefix].max_cap,
      visible,
      modelType: ModelType.NUMBER,
      min: 0,
      step: 10,
      unit,
      label: T.ectoplanner.form.shared.maxcap.label
    }
  ];
};

type ThermalEfficiencyPrefix =
  | 'absorptionChillerBu'
  | 'cHPBu'
  | 'electricalHeater'
  | 'electricalHeaterBu'
  | 'gasBoiler'
  | 'gasBoilerBu';

const thermalEfficiencyModel = (
  prefix: ThermalEfficiencyPrefix,
  visible: EctoplannerBoolFunction
): EctoplannerModelDefinition => {
  return {
    key: (input) => input[prefix].eta_th,
    modelType: ModelType.NUMBER,
    min: 50,
    max: 100,
    unit: T.ectoplanner.units.percent,
    visible: visible,
    label: T.ectoplanner.form.shared.etath.label,
    helpText: T.ectoplanner.form.shared.etath.helptext
  };
};

const maxCapModelsKwh = (
  prefix: MaxCapPrefixType,
  visible: EctoplannerBoolFunction
) => maxCapModels(prefix, visible, T.ectoplanner.units.kwh);
const maxCapModelsKwel = (
  prefix: MaxCapPrefixType,
  visible: EctoplannerBoolFunction
) => maxCapModels(prefix, visible, T.ectoplanner.units.kwel);

const hasError = (
  value: unknown,
  input: EctoplannerForm,
  environment: EctoplannerFormEnvironment,
  model: EctoplannerModelDefinition
) => errorText(value, input, environment, model) != null;

const errorText = (
  value: unknown,
  input: EctoplannerForm,
  environment: EctoplannerFormEnvironment,
  model: EctoplannerModelDefinition
) => {
  // Not visible: means that model is not used in calculation, therefore do not validate
  if (
    model.visible != null &&
    !(
      model.visible as ModelBoolFunctionRootProperty<
        EctoplannerForm,
        EctoplannerFormEnvironment
      >
    )(input, environment, model)
  ) {
    return null;
  }

  if (
    model.modelType === ModelType.BOOL ||
    model.modelType === ModelType.SPACE
  ) {
    return null;
  }

  if (value == null) {
    if (!model.optional) {
      return T.ectoplanner.validationerror.notset;
    }

    return null;
  }

  if (model.modelType === ModelType.NUMBER) {
    const numberValue = value as number;
    if (model.min != null && numberValue < model.min) {
      return T.format(T.ectoplanner.validationerror.minformat, model.min);
    }

    if (model.max != null && numberValue > model.max) {
      return T.format(T.ectoplanner.validationerror.maxformat, model.max);
    }
  }

  return null;
};

export const withValidation = (
  sections: EctoplannerSectionType[],
  horizontalWeightsDefault: HorizontalLabelValueWeights = ectoplannerHorizontalWeights
): EctoplannerSectionType[] => {
  const patchModel = (model: EctoplannerModelDefinition) => {
    if (model.hasError == null) {
      model.hasError = hasError;
    }

    if (model.errorText == null) {
      model.errorText = errorText;
    }

    model.isHorizontal = true;
    model.horizontalWeights = horizontalWeightsDefault;
  };

  for (const section of sections) {
    _.forEach(section.models, patchModel);

    for (const line of section.lines ?? []) {
      _.forEach(line.models, patchModel);
    }

    if (section.sections != null) {
      withValidation(section.sections, horizontalWeightsDefault);
    }
  }

  return sections;
};

const COPCarnotEfficiencyModel = (
  prefix:
    | 'wasteHeat'
    | 'wasteHeat2'
    | 'chiller'
    | 'compressionChillerBu'
    | 'heatPump'
    | 'wasteCooling',
  visible: EctoplannerBoolFunction,
  helpText: ModelDynamicStringProperty<
    EctoplannerForm,
    EctoplannerFormEnvironment,
    number
  > = null
): EctoplannerModelDefinition => {
  return {
    key: (input) => input[prefix].cop_carnot_eff,
    modelType: ModelType.NUMBER,
    visible,
    min: 10,
    max: 90,
    unit: T.ectoplanner.units.percent,
    label: T.ectoplanner.form.shared.ASHPcarnoteff.label,
    helpText
  };
};

const ASHPCarnotEfficiencyModel = (
  prefix: 'revHeatPumpBu' | 'revHeatPumpBu2' | 'simpleHeatPumpBu',
  visible: EctoplannerBoolFunction
): EctoplannerModelDefinition => ({
  key: (input) => input[prefix].ASHP_carnot_eff,
  modelType: ModelType.NUMBER,
  visible,
  min: 0,
  max: 100,
  step: 1,
  unit: T.ectoplanner.units.percent,
  label: T.ectoplanner.form.shared.ASHPcarnoteff.label
});

const spaceModel = (
  visible: EctoplannerBoolFunction = _.stubTrue
): EctoplannerModelDefinition => ({
  modelType: ModelType.SPACE,
  key: (input) => input, // TODO: Will result in empty key - what do do?
  visible
});

type InvestmentAmountPrefix =
  | 'revHeatPumpBu'
  | 'revHeatPumpBu2'
  | 'absorptionChillerBu'
  | 'accuTankBu'
  | 'aquiferStorageBu'
  | 'batteryBu'
  | 'cHPBu'
  | 'chiller'
  | 'coldStorageBu'
  | 'compressionChillerBu'
  | 'districtCoolingBu'
  | 'districtHeatingBu'
  | 'electricalHeater'
  | 'electricalHeaterBu'
  | 'freeCooling'
  | 'gSHeatPumpBu'
  | 'gasBoiler'
  | 'gasBoilerBu'
  | 'heatPump'
  | 'heatStorageBu'
  | 'photovoltaic'
  | 'solarThermal'
  | 'simpleHeatPumpBu';

const investmentAmountModel = (
  prefix: InvestmentAmountPrefix,
  visible: EctoplannerBoolFunction,
  investmentUnit: React.ReactNode
): EctoplannerModelDefinition => ({
  key: (input) => input[prefix].inv_var,
  modelType: ModelType.NUMBER,
  visible,
  min: 0,
  step: 10,
  unit: investmentUnit,
  label: T.ectoplanner.form.shared.invvar.label,
  helpText: T.ectoplanner.form.shared.invvar.helptext
});

const potentialTimeSeriesModels = (
  prefix: 'wasteCooling' | 'wasteHeat' | 'wasteHeat2',
  visible: EctoplannerBoolFunction,
  enableLabel: string,
  energyKind: EctoplannerDemandType
): EctoplannerModelDefinition[] => [
  {
    key: (input) => input[prefix].enable_potential_upload,
    modelType: ModelType.BOOL,
    visible,
    label: enableLabel,
    withDivider: false
  },
  {
    key: (input) => input[prefix].potential_time_series,
    modelType: ModelType.CUSTOM,
    render: (props, model) => (
      <EctoplannerProfileEditor
        {...props}
        model={model}
        energyKind={energyKind}
      />
    ),
    visible: (form: EctoplannerForm) =>
      visible(form, null, null) &&
      _.get(form, prefix + '.enable_potential_upload'),
    label: T.ectoplanner.form.shared.potentialtimeseries.label,
    horizontalWeights: fileHorizontalWeights
  }
];

const districtSupplyLimitModels = (
  prefix: 'districtCoolingBu' | 'districtHeatingBu',
  visible: EctoplannerBoolFunction
): EctoplannerModelDefinition[] => [
  {
    key: (input) => input[prefix].enable_supply_limit,
    modelType: ModelType.BOOL,
    visible,
    withDivider: true,
    label: T.ectoplanner.form.shared.enablesupplylimit.label
  },
  {
    key: (input) => input[prefix].supply_limit,
    modelType: ModelType.NUMBER,
    visible: (form: EctoplannerForm) =>
      visible(form, null, null) && _.get(form, prefix + '.enable_supply_limit'),
    label: T.ectoplanner.form.shared.supplylimit.label,
    min: 0,
    step: 10,
    unit: T.ectoplanner.units.mwha
  }
];

type InvestmentPrefix =
  | 'revHeatPumpBu'
  | 'revHeatPumpBu2'
  | 'absorptionChillerBu'
  | 'accuTankBu'
  | 'aquiferStorageBu'
  | 'batteryBu'
  | 'cHPBu'
  | 'coldStorageBu'
  | 'compressionChillerBu'
  | 'districtCoolingBu'
  | 'districtHeatingBu'
  | 'electricalHeaterBu'
  | 'gSHeatPumpBu'
  | 'gasBoilerBu'
  | 'heatStorageBu'
  | 'photovoltaic'
  | 'solarThermal'
  | 'simpleHeatPumpBu';

const investmentModels = (
  prefix: InvestmentPrefix,
  visible: EctoplannerBoolFunction,
  investmentUnit: React.ReactNode = T.ectoplanner.units.eurkwth
): EctoplannerModelDefinition[] => {
  return [
    investmentAmountModel(prefix, visible, investmentUnit),
    {
      key: (input) => input[prefix].life_time,
      modelType: ModelType.NUMBER,
      visible,
      min: 5,
      max: 80,
      unit: T.ectoplanner.units.years,
      label: T.ectoplanner.form.shared.lifetime.label,
      helpText: T.ectoplanner.form.shared.lifetime.helptext
    },
    {
      key: (input) => input[prefix].cost_om,
      modelType: ModelType.NUMBER,
      visible,
      min: 0,
      max: 20,
      step: 1,
      unit: T.ectoplanner.units.percentofinvest,
      label: T.ectoplanner.form.shared.costom.label
    }
  ];
};

const revHeatPumpEnabled =
  (prefix: 'revHeatPumpBu' | 'revHeatPumpBu2') => (form: EctoplannerForm) => {
    return form[prefix].enabled;
  };

const revHeatPumpEnabledWithCopOption =
  (prefix: 'revHeatPumpBu' | 'revHeatPumpBu2', copOption: string) =>
  (form: EctoplannerForm) =>
    form[prefix].enabled && form[prefix].cop_option === copOption;

export const RevHeatPumpBuSection = (
  prefix: 'revHeatPumpBu' | 'revHeatPumpBu2'
) =>
  withValidation([
    {
      label:
        T.ectoplanner.form.shared.airsourceheatpump +
        (prefix === 'revHeatPumpBu2' ? ' 2' : ''), // TOO
      models: [
        {
          key: (input) => input[prefix].enabled,
          modelType: ModelType.BOOL,
          label: T.ectoplanner.form.shared.airsourceheatpump
        },
        ...maxCapModels(prefix, revHeatPumpEnabled(prefix)),
        ...investmentModels(prefix, revHeatPumpEnabled(prefix)),
        {
          key: (input) => input[prefix].cop_option,
          label: T.ectoplanner.form.revheatpumpbu.cop.label,
          modelType: ModelType.OPTIONS,
          options: copOptionsWithUpload,
          visible: revHeatPumpEnabled(prefix)
        },
        {
          key: (input) => input[prefix].consider_defrosting,
          modelType: ModelType.BOOL,
          visible: revHeatPumpEnabled(prefix),
          label: T.ectoplanner.form.revheatpumpbu.consider_defrosting.label,
          helpText:
            T.ectoplanner.form.revheatpumpbu.consider_defrosting.helptext
        },
        {
          key: (input) => input[prefix].cop_heat_time_series,
          modelType: ModelType.CUSTOM,
          render: (props, model) => (
            <EctoplannerProfileEditor
              {...props}
              model={model}
              energyKind={EctoplannerDemandTypes.Heating}
              profileType={EctoplannerProfileTypes.COP}
            />
          ),
          label: T.ectoplanner.form.revheatpumpbu.cop_heat_timeseries.label,
          optional: false,
          visible: (form: EctoplannerForm) =>
            revHeatPumpEnabledWithCopOption(prefix, 'upload')(form),
          horizontalWeights: fileHorizontalWeights
        },
        {
          key: (input) => input[prefix].cop_cool_time_series,
          modelType: ModelType.CUSTOM,
          render: (props, model) => (
            <EctoplannerProfileEditor
              {...props}
              model={model}
              energyKind={EctoplannerDemandTypes.Cooling}
              profileType={EctoplannerProfileTypes.COP}
            />
          ),
          label: T.ectoplanner.form.revheatpumpbu.cop_cool_timeseries.label,
          visible: (form: EctoplannerForm) =>
            revHeatPumpEnabledWithCopOption(prefix, 'upload')(form),
          horizontalWeights: fileHorizontalWeights
        },
        {
          key: (input) => input[prefix].hp_model,
          label: T.ectoplanner.form.building.params.productdata.label,
          modelType: ModelType.OPTIONS,
          options: airSourceHeatPumpOptions,
          hasError: (model: string, form: EctoplannerForm) => {
            const copOption = form[prefix].cop_option;
            if (copOption !== 'productData') {
              return false;
            }

            return (
              isNullOrWhitespace(model) ||
              airSourceHeatPumpOptions.find((x) => x.value === model) == null
            );
          },
          visible: (form: EctoplannerForm) =>
            revHeatPumpEnabledWithCopOption(prefix, 'productData')(form)
        },
        ASHPCarnotEfficiencyModel(prefix, (form: EctoplannerForm) =>
          revHeatPumpEnabledWithCopOption(prefix, 'carnot')(form)
        ),
        {
          key: (input) => input[prefix].max_COP_heat,
          modelType: ModelType.NUMBER,
          visible: (form: EctoplannerForm) =>
            revHeatPumpEnabledWithCopOption(prefix, 'carnot')(form),
          ...numberPercentOptions,
          label: T.ectoplanner.form.revheatpumpbu.maxCOPheat.label
        },
        {
          key: (input) => input[prefix].max_COP_cool,
          visible: (form: EctoplannerForm) =>
            revHeatPumpEnabledWithCopOption(prefix, 'carnot')(form),
          modelType: ModelType.NUMBER,
          ...numberPercentOptions,
          label: T.ectoplanner.form.revheatpumpbu.maxCOPcool.label
        },
        {
          key: (input) => input[prefix].cop_const_heat,
          modelType: ModelType.NUMBER,
          visible: (form: EctoplannerForm) =>
            revHeatPumpEnabledWithCopOption(prefix, 'const')(form),
          ...numberPercentOptions,
          label: T.ectoplanner.form.revheatpumpbu.cop_const_heat.label
        },
        {
          key: (input) => input[prefix].cop_const_cool,
          modelType: ModelType.NUMBER,
          visible: (form: EctoplannerForm) =>
            revHeatPumpEnabledWithCopOption(prefix, 'const')(form),
          ...numberPercentOptions,
          label: T.ectoplanner.form.revheatpumpbu.cop_const_cool.label
        }
      ]
    }
  ])[0];

export const RevHeatPumpBuTech1 = {
  label: T.ectoplanner.form.shared.airsourceheatpump,
  icon: ImageHeat_pump_reversible,
  section: RevHeatPumpBuSection('revHeatPumpBu'),
  id: 'revHeatPumpBu',
  enabled: (form: EctoplannerForm) => form.revHeatPumpBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.revHeatPumpBu.max_cap / 1000.0,
      T.ectoplanner.units.mwth
    )
};

export const RevHeatPumpBuTech2 = {
  label: T.ectoplanner.form.shared.airsourceheatpump + ' 2',
  icon: ImageHeat_pump_reversible,
  section: RevHeatPumpBuSection('revHeatPumpBu2'),
  id: 'revHeatPumpBu2',
  enabled: (form: EctoplannerForm) => form.revHeatPumpBu2.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.revHeatPumpBu2.max_cap / 1000.0,
      T.ectoplanner.units.mwth
    )
};

const gSHeatPumpEnabled = (form: EctoplannerForm) => form.gSHeatPumpBu.enabled;

export const GSHeatPumpBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.groundsourceheatpump,
    models: [
      {
        key: (input) => input.gSHeatPumpBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.groundsourceheatpump
      },
      ...maxCapModels('gSHeatPumpBu', gSHeatPumpEnabled),
      ...investmentModels('gSHeatPumpBu', gSHeatPumpEnabled),
      {
        key: (input) => input.gSHeatPumpBu.cop_const_heat,
        visible: gSHeatPumpEnabled,
        modelType: ModelType.NUMBER,
        ...copNumberOptions,
        label: T.ectoplanner.form.shared.heatingcopconstant
      },
      {
        key: (input) => input.gSHeatPumpBu.cop_const_cool,
        visible: gSHeatPumpEnabled,
        modelType: ModelType.NUMBER,
        min: 1,
        max: 40,
        step: 0.1,
        label: T.ectoplanner.form.gsheatpumpbu.copconstcool.label
      }
    ]
  }
])[0];

export const GSHeatPumpBuTech = {
  section: GSHeatPumpBuSection,
  id: 'gSHeatPumpBu',
  label: T.ectoplanner.form.shared.groundsourceheatpump,
  icon: ImageGeothermal,
  enabled: (form: EctoplannerForm) => form.gSHeatPumpBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.gSHeatPumpBu.max_cap / 1000.0,
      T.ectoplanner.units.mwth
    )
};

const simpleHeatPumpBuEnabled = (form: EctoplannerForm) =>
  form.simpleHeatPumpBu.enabled;
// For some reason cop_is_const in this model means "Use ground source heat pump"
const simpleHeatPumpGroundSourceEnabled = (form: EctoplannerForm) =>
  form.simpleHeatPumpBu.enabled && form.simpleHeatPumpBu.cop_is_const;
const simpleHeatPumpAirSourceEnable = (form: EctoplannerForm) =>
  form.simpleHeatPumpBu.enabled && form.simpleHeatPumpBu.is_ASHP;

export const SimpleHeatPumpBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.heatpumpheatingonly,
    models: [
      {
        key: (input) => input.simpleHeatPumpBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.heatpumpheatingonly
      },
      ...maxCapModels('simpleHeatPumpBu', simpleHeatPumpBuEnabled),
      ...investmentModels('simpleHeatPumpBu', simpleHeatPumpBuEnabled),
      {
        key: (input) => input.simpleHeatPumpBu.cop_is_const,
        onDidUpdate: (_name: string[], value: boolean) => {
          return [[(input) => input.simpleHeatPumpBu.is_ASHP, !value]];
        },
        modelType: ModelType.BOOL,
        visible: simpleHeatPumpBuEnabled,
        label: T.ectoplanner.form.simpleheatpumpbu.copisconst.label
      },
      {
        key: (input) => input.simpleHeatPumpBu.is_ASHP,
        modelType: ModelType.BOOL,
        visible: simpleHeatPumpBuEnabled,
        onDidUpdate: (_name: string[], value) => {
          return [[(input) => input.simpleHeatPumpBu.cop_is_const, !value]];
        },
        withDivider: true,
        label: T.ectoplanner.form.simpleheatpumpbu.isASHP.label
      },
      {
        key: (input) => input.simpleHeatPumpBu.cop_const,
        modelType: ModelType.NUMBER,
        ...copNumberOptions,
        visible: simpleHeatPumpGroundSourceEnabled,
        label: T.ectoplanner.form.shared.heatingcopconstant
      },
      {
        key: (input) => input.simpleHeatPumpBu.max_COP,
        modelType: ModelType.NUMBER,
        ...copNumberOptions,
        visible: simpleHeatPumpGroundSourceEnabled,
        label: T.ectoplanner.form.shared.maximumcop
      },
      ASHPCarnotEfficiencyModel(
        'simpleHeatPumpBu',
        simpleHeatPumpAirSourceEnable
      )
    ]
  }
])[0];

export const SimpleHeatPumpBuTech = {
  label: T.ectoplanner.form.shared.heatpump,
  icon: ImageHeat_pump_heat_only,
  section: SimpleHeatPumpBuSection,
  id: 'simpleHeatPumpBu',
  enabled: (form: EctoplannerForm) => form.simpleHeatPumpBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.simpleHeatPumpBu.max_cap / 1000.0,
      T.ectoplanner.units.mwth
    )
};

const absorptionChillerBuEnabled = (form: EctoplannerForm) =>
  form.absorptionChillerBu.enabled;

export const AbsorptionChillerBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.absorptionchiller,
    models: [
      {
        key: (input) => input.absorptionChillerBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.absorptionchiller
      },
      ...maxCapModels('absorptionChillerBu', absorptionChillerBuEnabled),
      thermalEfficiencyModel('absorptionChillerBu', absorptionChillerBuEnabled),
      ...investmentModels(
        'absorptionChillerBu',
        absorptionChillerBuEnabled,
        T.ectoplanner.units.eurkw
      )
    ]
  }
])[0];

export const AbsorptionChillerBuTech = {
  label: T.ectoplanner.form.shared.absorptionchiller,
  icon: ImageAC,
  section: AbsorptionChillerBuSection,
  id: 'absorptionChillerBu',
  enabled: (form: EctoplannerForm) => form.absorptionChillerBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.absorptionChillerBu.max_cap / 1000.0,
      T.ectoplanner.units.mwth
    )
};

const formObjectEnabled =
  (name: string) =>
  (form: EctoplannerForm, _unused: EctoplannerFormEnvironment = null) =>
    _.get(form, name) === true;
const accuTankBuEnabled = formObjectEnabled('accuTankBu.enabled');

export const AccuTankBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.accumulatortank,
    models: [
      {
        key: (input) => input.accuTankBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.accumulatortank,
        helpText: T.ectoplanner.form.accutankbu.enabled.helptext
      },
      ...investmentModels(
        'accuTankBu',
        accuTankBuEnabled,
        T.ectoplanner.units.eurm3
      ),
      {
        key: (input) => input.accuTankBu.min_vol,
        modelType: ModelType.NUMBER,
        visible: accuTankBuEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.m3,
        label: T.ectoplanner.form.accutankbu.minvol.label
      },
      {
        key: (input) => input.accuTankBu.max_vol,
        modelType: ModelType.NUMBER,
        visible: accuTankBuEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.m3,
        label: T.ectoplanner.form.accutankbu.maxvol.label
      }
    ]
  }
])[0];

export const AccuTankBuTech = {
  label: T.ectoplanner.form.shared.accumulatortank,
  icon: ImagePassiveBalancingUnit,
  section: AccuTankBuSection,
  id: 'accuTankBu',
  enabled: (form: EctoplannerForm) => form.accuTankBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(form.accuTankBu.max_vol, T.ectoplanner.units.m3)
};

const aquiferStorageEnabled = formObjectEnabled('aquiferStorageBu.enabled');

export const AquiferStorageBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.aquiferstorage,
    models: [
      {
        key: (input) => input.aquiferStorageBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.aquiferstorage,
        helpText: T.ectoplanner.form.aquiferstoragebu.enabled.helptext
      },
      ...investmentModels('aquiferStorageBu', aquiferStorageEnabled),
      {
        key: (input) => input.aquiferStorageBu.max_ch_energy,
        modelType: ModelType.NUMBER,
        visible: aquiferStorageEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.mwhyear,
        label: T.ectoplanner.form.aquiferstoragebu.maxchenergy.label,
        helpText: T.ectoplanner.form.aquiferstoragebu.maxchenergy.helptext
      },
      {
        key: (input) => input.aquiferStorageBu.max_ch_power,
        modelType: ModelType.NUMBER,
        visible: aquiferStorageEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.kw,
        label: T.ectoplanner.form.aquiferstoragebu.maxchpower.label,
        helpText: T.ectoplanner.form.aquiferstoragebu.maxchpower.helptext
      },
      {
        key: (input) => input.aquiferStorageBu.round_eff_enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.aquiferstoragebu.roundeffenabled.label
      },
      {
        key: (input) => input.aquiferStorageBu.round_eff_min,
        modelType: ModelType.NUMBER,
        visible: (form: EctoplannerForm) =>
          aquiferStorageEnabled(form) &&
          form.aquiferStorageBu.round_eff_enabled,
        min: 0,
        max: 1000,
        step: 1,
        unit: T.ectoplanner.units.percent,
        label: T.ectoplanner.form.aquiferstoragebu.roundeffmin.label,
        helpText: T.ectoplanner.form.aquiferstoragebu.roundeff.helptext
      },
      {
        key: (input) => input.aquiferStorageBu.round_eff_max,
        modelType: ModelType.NUMBER,
        visible: (form: EctoplannerForm) =>
          aquiferStorageEnabled(form) &&
          form.aquiferStorageBu.round_eff_enabled,
        min: 0,
        max: 1000,
        step: 1,
        unit: T.ectoplanner.units.percent,
        label: T.ectoplanner.form.aquiferstoragebu.roundeffmax.label
      },
      {
        key: (input) => input.aquiferStorageBu.pump_work,
        modelType: ModelType.NUMBER,
        visible: aquiferStorageEnabled,
        min: 0,
        max: 50,
        step: 1,
        unit: T.ectoplanner.units.percent,
        label: T.ectoplanner.form.aquiferstoragebu.pumpwork.label,
        helpText: T.ectoplanner.form.aquiferstoragebu.pumpwork.helptext
      }
    ]
  }
])[0];

export const AquiferStorageBuTech = {
  label: T.ectoplanner.form.shared.aquiferstorage,
  icon: ImageAquifer_storage,
  section: AquiferStorageBuSection,
  id: 'aquiferStorageBu',
  enabled: (form: EctoplannerForm) => form.aquiferStorageBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.aquiferStorageBu.max_ch_power / 1000.0,
      T.ectoplanner.units.mw
    )
};

const batteryBuEnable = formObjectEnabled('batteryBu.enabled');

export const BatteryBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.battery,
    models: [
      {
        key: (input) => input.batteryBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.battery,
        helpText: T.ectoplanner.form.batterybu.enabled.helptext
      },
      ...investmentModels(
        'batteryBu',
        batteryBuEnable,
        T.ectoplanner.units.eurkwh
      ),
      ...maxCapModelsKwh('batteryBu', batteryBuEnable)
    ]
  }
])[0];

export const BatteryBuTech = {
  section: BatteryBuSection,
  label: T.ectoplanner.form.shared.battery,
  icon: ImageBAT,
  id: 'batteryBu',
  enabled: (form: EctoplannerForm) => form.batteryBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.batteryBu.max_cap / 1000.0,
      T.ectoplanner.units.mwh
    )
};

const chpBuEnabled = (form: EctoplannerForm) => form.cHPBu.enabled;

export const CHPBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.chpunit,
    models: [
      {
        key: (input) => input.cHPBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.chpunit
      },
      ...maxCapModelsKwel('cHPBu', chpBuEnabled),
      ...investmentModels('cHPBu', chpBuEnabled, T.ectoplanner.units.eurkwel),
      {
        key: (input) => input.cHPBu.eta_el,
        modelType: ModelType.NUMBER,
        visible: chpBuEnabled,
        min: 10,
        max: 80,
        unit: T.ectoplanner.units.percent,
        label: T.ectoplanner.form.chpbu.etael.label,
        helpText: T.ectoplanner.form.chpbu.etael.helptext
      },
      thermalEfficiencyModel('cHPBu', chpBuEnabled)
    ]
  }
])[0];

export const CHPBUTech = {
  label: T.ectoplanner.form.shared.chpunit,
  icon: ImageCHP_ICE,
  section: CHPBuSection,
  id: 'cHPBu',
  enabled: (form: EctoplannerForm) => form.cHPBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(form.cHPBu.max_cap / 1000.0, T.ectoplanner.units.mwel)
};

const coldStorageBuEnabled = formObjectEnabled('coldStorageBu.enabled');

export const ColdStorageBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.coldstorage,
    models: [
      {
        key: (input) => input.coldStorageBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.coldstorage,
        helpText: T.ectoplanner.form.coldstoragebu.enabled.helptext
      },
      ...investmentModels(
        'coldStorageBu',
        coldStorageBuEnabled,
        T.ectoplanner.units.eurkwh
      ),
      {
        key: (input) => input.coldStorageBu.sto_loss,
        modelType: ModelType.NUMBER,
        visible: coldStorageBuEnabled,
        min: 0,
        max: 50,
        unit: T.ectoplanner.units.percentperhour,
        label: T.ectoplanner.form.shared.storageloss
      },
      ...maxCapModelsKwh('coldStorageBu', coldStorageBuEnabled)
    ]
  }
])[0];

export const ColdStorageBuTech = {
  label: T.ectoplanner.form.shared.coldstorage,
  icon: ImageColdStorage,
  section: ColdStorageBuSection,
  id: 'coldStorageBu',
  enabled: (form: EctoplannerForm) => form.coldStorageBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.coldStorageBu.max_cap / 1000.0,
      T.ectoplanner.units.mwh
    )
};

const compressionChillerEnabled = (form: EctoplannerForm) =>
  form.compressionChillerBu.enabled;
const compressionChillerConstantCOPEnabled = (form: EctoplannerForm) =>
  form.compressionChillerBu.enabled && form.compressionChillerBu.cop_is_const;
const compressionChillerCarnotEnabled = (form: EctoplannerForm) =>
  form.compressionChillerBu.enabled &&
  form.compressionChillerBu.cop_with_carnot;

export const CompressionChillerBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.compressionchiller,
    models: [
      {
        key: (input) => input.compressionChillerBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.compressionchiller
      },
      ...maxCapModels('compressionChillerBu', compressionChillerEnabled),
      ...investmentModels('compressionChillerBu', compressionChillerEnabled),
      {
        key: (input) => input.compressionChillerBu.cop_is_const,
        modelType: ModelType.BOOL,
        visible: compressionChillerEnabled,
        onDidUpdate: (_name: string[], value: boolean) => {
          return [
            [(input) => input.compressionChillerBu.cop_with_carnot, !value]
          ];
        },
        label: T.ectoplanner.form.shared.useconstantcop
      },
      {
        key: (input) => input.compressionChillerBu.cop_with_carnot,
        modelType: ModelType.BOOL,
        visible: compressionChillerEnabled,
        withDivider: true,
        onDidUpdate: (_name: string[], value: boolean) => {
          return [[(input) => input.compressionChillerBu.cop_is_const, !value]];
        },
        label: T.ectoplanner.form.shared.usecarnotefficiency
      },
      {
        key: (input) => input.compressionChillerBu.cop_const,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.shared.copconst.label,
        visible: compressionChillerConstantCOPEnabled,
        min: 0,
        max: 100,
        step: 0.1
      },
      COPCarnotEfficiencyModel(
        'compressionChillerBu',
        compressionChillerCarnotEnabled,
        T.ectoplanner.form.compressionchillerbu.copcarnoteff.helptext
      ),
      {
        key: (input) => input.compressionChillerBu.max_COP,
        modelType: ModelType.NUMBER,
        visible: compressionChillerCarnotEnabled,
        min: 0,
        max: 100,
        label: T.ectoplanner.form.shared.maximumcop
      },
      {
        key: (input) => input.compressionChillerBu.passive_cooling_enabled,
        modelType: ModelType.BOOL,
        visible: compressionChillerEnabled,
        label: T.ectoplanner.form.compressionchiller.passive_cooling_enabled
      }
    ]
  }
])[0];

export const CompressionChillerBuTech = {
  label: T.ectoplanner.form.shared.compressionchiller,
  icon: ImageCC,
  section: CompressionChillerBuSection,
  id: 'compressionChillerBu',
  enabled: (form: EctoplannerForm) => form.compressionChillerBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.compressionChillerBu.max_cap / 1000.0,
      T.ectoplanner.units.mwth
    )
};

const districtCoolingEnabled = formObjectEnabled('districtCoolingBu.enabled');

export const DistrictCoolingBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.districtcooling,
    models: [
      {
        key: (input) => input.districtCoolingBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.districtcooling
      },
      {
        key: (input) => input.districtCoolingBu.price,
        modelType: ModelType.NUMBER,
        visible: districtCoolingEnabled,
        min: 0,
        step: 0.001,
        unit: T.ectoplanner.units.eurkwh,
        label: T.ectoplanner.form.shared.price
      },
      {
        key: (input) => input.districtCoolingBu.cap,
        modelType: ModelType.NUMBER,
        visible: districtCoolingEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.kw,
        label: T.ectoplanner.form.districtcoolingbu.cap.label
      },
      ...investmentModels('districtCoolingBu', districtCoolingEnabled),
      ...districtSupplyLimitModels('districtCoolingBu', districtCoolingEnabled)
    ]
  }
])[0];

export const DistrictCoolingBuTech = {
  label: T.ectoplanner.form.shared.districtcooling,
  icon: ImageDistrict_cooling_2,
  section: DistrictCoolingBuSection,
  id: 'districtCoolingBu',
  enabled: (form: EctoplannerForm) => form.districtCoolingBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.districtCoolingBu.cap / 1000.0,
      T.ectoplanner.units.mw
    )
};

const districtHeatingEnabled = formObjectEnabled('districtHeatingBu.enabled');

export const DistrictHeatingBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.districtheating,
    models: [
      {
        key: (input) => input.districtHeatingBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.districtheating
      },
      {
        key: (input) => input.districtHeatingBu.price,
        modelType: ModelType.NUMBER,
        visible: districtHeatingEnabled,
        min: 0,
        step: 0.001,
        unit: T.ectoplanner.units.eurkwh,
        label: T.ectoplanner.form.shared.price
      },
      {
        key: (input) => input.districtHeatingBu.cap,
        modelType: ModelType.NUMBER,
        visible: districtHeatingEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.kw,
        label: T.ectoplanner.form.districtheatingbu.cap.label
      },
      ...investmentModels('districtHeatingBu', districtHeatingEnabled),
      ...districtSupplyLimitModels('districtHeatingBu', districtHeatingEnabled)
    ]
  }
])[0];

export const DistrictHeatingBuTech = {
  label: T.ectoplanner.form.shared.districtheating,
  icon: ImageDistrict_heating_2,
  section: DistrictHeatingBuSection,
  id: 'districtHeatingBu',
  enabled: (form: EctoplannerForm) => form.districtHeatingBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.districtHeatingBu.cap / 1000.0,
      T.ectoplanner.units.mw
    )
};

export const EcologicalImpactSections = withValidation([
  {
    label: T.ectoplanner.form.ecologicalimpact.title,
    models: [
      {
        key: (input) => input.ecologicalImpact.co2_el,
        modelType: ModelType.NUMBER,
        min: 0,
        max: 2,
        step: 0.01,
        unit: T.ectoplanner.units.kgkwh,
        label: T.ectoplanner.form.ecologicalimpact.co2el.label,
        helpText: T.ectoplanner.form.ecologicalimpact.co2el.helptext
      },
      {
        key: (input) => input.ecologicalImpact.co2_gas,
        modelType: ModelType.NUMBER,
        min: 0,
        max: 1,
        step: 0.01,
        unit: T.ectoplanner.units.kgkwh,
        label: T.ectoplanner.form.ecologicalimpact.co2gas.label,
        helpText: T.ectoplanner.form.ecologicalimpact.co2gas.helptext
      },
      {
        key: (input) => input.ecologicalImpact.grid_primary_energy,
        label: T.ectoplanner.form.ecologicalimpact.grid_primary_energy,
        ...kwhKwhNumberSettings
      },
      {
        key: (input) => input.ecologicalImpact.gas_primary_energy,
        label: T.ectoplanner.form.ecologicalimpact.gas_primary_energy,
        ...kwhKwhNumberSettings
      },
      {
        key: (input) => input.ecologicalImpact.district_heating_primary_energy,
        label:
          T.ectoplanner.form.ecologicalimpact.district_heating_primary_energy,
        ...kwhKwhNumberSettings
      },
      {
        key: (input) => input.ecologicalImpact.district_cooling_primary_energy,
        label:
          T.ectoplanner.form.ecologicalimpact.district_cooling_primary_energy,
        ...kwhKwhNumberSettings
      },
      {
        key: (input) => input.ecologicalImpact.waste_heat_primary_energy,
        label: T.ectoplanner.form.ecologicalimpact.waste_heat_primary_energy,
        ...kwhKwhNumberSettings
      },
      {
        key: (input) => input.ecologicalImpact.waste_cold_primary_energy,
        label: T.ectoplanner.form.ecologicalimpact.waste_cold_primary_energy,
        ...kwhKwhNumberSettings
      },
      {
        key: (input) => input.ecologicalImpact.district_heating_co2,
        label: T.ectoplanner.form.ecologicalimpact.district_heating_co2,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.gkwh
      },
      {
        key: (input) => input.ecologicalImpact.district_cooling_co2,
        label: T.ectoplanner.form.ecologicalimpact.district_cooling_co2,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.gkwh
      },
      {
        key: (input) => input.ecologicalImpact.waste_heat_co2,
        label: T.ectoplanner.form.ecologicalimpact.waste_heat_co2,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.gkwh
      },
      {
        key: (input) => input.ecologicalImpact.waste_cold_co2,
        label: T.ectoplanner.form.ecologicalimpact.waste_cold_co2,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.gkwh
      }
    ]
  }
]);

const scenarioEnabled = formObjectEnabled('economicParameters.enable_scenario');

export const EconomicParametersSections = withValidation([
  {
    label: T.ectoplanner.form.economicparameters.section.general,
    models: [
      {
        key: (input) => input.economicParameters.interest_rate,
        modelType: ModelType.NUMBER,
        min: 1,
        max: 20,
        step: 0.1,
        unit: T.ectoplanner.units.percent,
        onDidUpdate: (_key: string[], value, form: EctoplannerForm) => {
          if (value != null) {
            return updateGasAndElectricityPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.economicparameters.interestrate.label,
        helpText: T.ectoplanner.form.economicparameters.interestrate.helptext
      },
      {
        key: (input) => input.economicParameters.project_lifetime,
        modelType: ModelType.NUMBER,
        min: 3,
        max: 80,
        unit: T.ectoplanner.units.years,
        onDidUpdate: (_key: string[], value, form: EctoplannerForm) => {
          if (value != null) {
            return updateGasAndElectricityPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.economicparameters.projectlifetime.label,
        helpText: T.ectoplanner.form.economicparameters.projectlifetime.helptext
      },
      {
        key: (input) => input.economicParameters.enable_scenario,
        modelType: ModelType.BOOL,
        withDivider: true,
        label: T.ectoplanner.form.economicparameters.enablescenario.label,
        helpText: T.ectoplanner.form.economicparameters.enablescenario.helptext
      },
      spaceModel(_.negate(scenarioEnabled)),
      {
        key: (input) => input.economicParameters.scenario,
        modelType: ModelType.OPTIONS,
        visible: scenarioEnabled,
        options: _.keys(EconomicScenarios).map((scenario) => ({
          label: scenario,
          value: scenario
        })),
        onDidUpdate: (_key: string[], value: number, form: EctoplannerForm) => {
          const scenarioData = _.get(EconomicScenarios, value);
          if (scenarioData) {
            const changes: [
              key: (path: EctoplannerForm) => unknown,
              value: unknown
            ][] = [
              [
                (input) => input.economicParameters.price_ex_el,
                scenarioData.price_ex_el
              ],
              [
                (input) => input.economicParameters.price_growth_el,
                scenarioData.price_growth_el
              ],
              [
                (input) => input.economicParameters.price_ex_gas,
                scenarioData.price_ex_gas
              ],
              [
                (input) => input.economicParameters.price_growth_gas,
                scenarioData.price_growth_gas
              ],
              [
                (input) => input.economicParameters.price_grid_el,
                scenarioData.price_grid_el
              ],
              [
                (input) => input.economicParameters.price_grid_gas,
                scenarioData.price_grid_gas
              ],
              [
                (input) => input.economicParameters.grid_growth_el,
                scenarioData.grid_growth_el
              ],
              [
                (input) => input.economicParameters.grid_growth_gas,
                scenarioData.grid_growth_gas
              ],
              [
                (input) => input.economicParameters.env_surcharge,
                scenarioData.env_surcharge
              ],
              [
                (input) => input.economicParameters.oth_surcharge,
                scenarioData.oth_surcharge
              ],
              [
                (input) => input.economicParameters.tax_rate,
                scenarioData.tax_rate
              ]
            ];

            return updateGasAndElectricityPriceWithChanges(changes, form);
          }
        },
        label: T.ectoplanner.form.economicparameters.scenario.label,
        helpText: T.ectoplanner.form.economicparameters.scenario.helptext
      },
      {
        key: (input) => input.economicParameters.tax_rate,
        modelType: ModelType.NUMBER,
        visible: scenarioEnabled,
        ...numberPercentOptions,
        onDidUpdate: (_key: string[], value: number, form: EctoplannerForm) => {
          if (value != null) {
            return updateGasAndElectricityPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.economicparameters.taxrate.label
      }
    ]
  },
  {
    wrapContent: true,
    visible: (form: EctoplannerForm) => form.economicParameters.enable_scenario,
    label: T.ectoplanner.form.economicparameters.section.electricity,
    models: [
      {
        modelType: ModelType.NUMBER,
        key: (input) => input.economicParameters.price_ex_el,
        visible: scenarioEnabled,
        ...eurKwhNumberSettings,
        onDidUpdate: (_key: string[], value, form: EctoplannerForm) => {
          if (value != null) {
            return updateElectricityPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.shared.initialwholesalemarketprice,
        helpText: T.ectoplanner.form.economicparameters.priceexel.helptext
      },
      {
        modelType: ModelType.NUMBER,
        key: (input) => input.economicParameters.price_growth_el,
        visible: scenarioEnabled,
        ...percentANumberSettings,
        onDidUpdate: (_key: string[], value, form: EctoplannerForm) => {
          if (value != null) {
            return updateElectricityPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.shared.yearlywholesalepricechangerate,
        helpText: T.ectoplanner.form.shared.changeratehelp
      },
      {
        modelType: ModelType.NUMBER,
        key: (input) => input.economicParameters.price_grid_el,
        visible: scenarioEnabled,
        ...eurKwhNumberSettings,
        onDidUpdate: (_key: string[], value, form: EctoplannerForm) => {
          if (value != null) {
            return updateElectricityPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.shared.initialgridsurcharge
      },
      {
        key: (input) => input.economicParameters.grid_growth_el,
        visible: scenarioEnabled,
        ...percentANumberSettings,
        onDidUpdate: (_key: string[], value: number, form: EctoplannerForm) => {
          if (value != null) {
            return updateElectricityPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.shared.yearlygridsurchargegrowthrate
      },
      {
        modelType: ModelType.NUMBER,
        key: (input) => input.economicParameters.env_surcharge,
        visible: scenarioEnabled,
        ...eurKwhNumberSettings,
        onDidUpdate: (_key: string[], value, form: EctoplannerForm) => {
          if (value != null) {
            return updateElectricityPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.economicparameters.envsurcharge.label,
        helpText: T.ectoplanner.form.economicparameters.envsurcharge.helptext
      }
    ]
  },
  {
    wrapContent: true,
    label: T.ectoplanner.form.economicparameters.section.gas,
    visible: (form: EctoplannerForm) => form.economicParameters.enable_scenario,
    models: [
      {
        modelType: ModelType.NUMBER,
        key: (input) => input.economicParameters.price_ex_gas,
        visible: scenarioEnabled,
        ...eurKwhNumberSettings,
        onDidUpdate: (_key: string[], value, form: EctoplannerForm) => {
          if (value != null) {
            return updateGasPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.shared.initialwholesalemarketprice,
        helpText: T.ectoplanner.form.economicparameters.priceexgas.helptext
      },
      {
        key: (input) => input.economicParameters.price_growth_gas,
        visible: scenarioEnabled,
        ...percentANumberSettings,
        onDidUpdate: (_key: string[], value: number, form: EctoplannerForm) => {
          if (value != null) {
            return updateGasPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.shared.yearlywholesalepricechangerate,
        helpText: T.ectoplanner.form.shared.changeratehelp
      },
      {
        key: (input) => input.economicParameters.price_grid_gas,
        visible: scenarioEnabled,
        ...eurKwhNumberSettings,
        onDidUpdate: (_key: string[], value: number, form: EctoplannerForm) => {
          if (value != null) {
            return updateGasPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.shared.initialgridsurcharge
      },
      {
        key: (input) => input.economicParameters.grid_growth_gas,
        visible: scenarioEnabled,
        ...percentANumberSettings,
        onDidUpdate: (_key: string[], value: number, form: EctoplannerForm) => {
          if (value != null) {
            return updateGasPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.shared.yearlygridsurchargegrowthrate
      },
      {
        modelType: ModelType.NUMBER,
        key: (input) => input.economicParameters.oth_surcharge,
        visible: scenarioEnabled,
        ...eurKwhNumberSettings,
        onDidUpdate: (_key: string[], value, form: EctoplannerForm) => {
          if (value != null) {
            return updateElectricityPriceWithChanges([], form);
          }
        },
        label: T.ectoplanner.form.economicparameters.othsurcharge.label
      }
    ]
  }
]);

const electricalHeaterBuEnabled = formObjectEnabled(
  'electricalHeaterBu.enabled'
);

export const ElectricalHeaterBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.electricboiler,
    models: [
      {
        key: (input) => input.electricalHeaterBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.electricboiler
      },
      ...maxCapModels('electricalHeaterBu', electricalHeaterBuEnabled),
      ...investmentModels('electricalHeaterBu', electricalHeaterBuEnabled),
      thermalEfficiencyModel('electricalHeaterBu', electricalHeaterBuEnabled)
    ]
  }
])[0];

export const ElectricalHeaterBuTech = {
  label: T.ectoplanner.form.shared.electricboiler,
  icon: ImageEH,
  section: ElectricalHeaterBuSection,
  id: 'electricalHeaterBu',
  enabled: (form: EctoplannerForm) => form.electricalHeaterBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.electricalHeaterBu.max_cap / 1000.0,
      T.ectoplanner.units.mwth
    )
};

export const SensitivitySections = withValidation([
  {
    wrapContent: true,
    label: T.ectoplanner.form.sensitivity.title,
    models: [
      {
        key: (input) => input.sensitivityEnabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.sensitivity.title
      },
      {
        key: (input) => input.sensitivityVals,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <EctoplannerNumericalArrayEditor
            {...props}
            label={T.ectoplanner.form.sensitivity.value}
            model={model}
          />
        ),
        visible: (form: EctoplannerForm) => form.sensitivityEnabled
      }
    ]
  }
]);

const generalInvestmentModels = (
  prefix: 'hp' | 'eh' | 'cc' | 'drc' | 'peak_chiller'
): EctoplannerModelDefinition[] => {
  return [
    {
      key: (input) => input.investment[makeKey(prefix, '_inv_var_general')],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 1000000000,
      step: 1,
      unit: T.ectoplanner.units.eurkw,
      label: T.ectoplanner.form.shared.invvar.label,
      helpText: T.ectoplanner.form.shared.invvar.helptext
    },
    {
      key: (input) => input.investment[makeKey(prefix, '_life_time_general')],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 200,
      step: 1,
      unit: T.ectoplanner.units.years,
      label: T.ectoplanner.form.shared.lifetime.label,
      helpText: T.ectoplanner.form.shared.lifetime.helptext
    },
    {
      key: (input) => input.investment[makeKey(prefix, '_om_general')],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 100,
      step: 1,
      unit: T.ectoplanner.units.percent,
      label: T.ectoplanner.form.shared.costom.label
    }
  ];
};

export const InvestmentSections = withValidation([
  {
    wrapContent: true,
    label: T.ectoplanner.form.investment.title,
    helpText: T.ectoplanner.form.investment.helptext,
    sections: [
      {
        label: T.ectoplanner.form.investment.hp.label,
        wrapContent: true,
        models: generalInvestmentModels('hp')
      },
      {
        label: T.ectoplanner.form.investment.eh.label,
        wrapContent: true,
        models: generalInvestmentModels('eh')
      },
      {
        label: T.ectoplanner.form.investment.cc.label,
        wrapContent: true,
        models: generalInvestmentModels('cc')
      },
      {
        label: T.ectoplanner.form.investment.peak_chiller.label,
        wrapContent: true,
        models: generalInvestmentModels('peak_chiller')
      },
      {
        label: T.ectoplanner.form.investment.drc.label,
        wrapContent: true,
        models: generalInvestmentModels('drc')
      }
    ]
  }
]);

export const EnergyCostsSections = withValidation([
  {
    wrapContent: true,
    label: T.ectoplanner.form.energycosts.title,
    models: [
      {
        key: (input) => input.energyCosts.price_gas,
        enabled: _.negate(scenarioEnabled),
        ...eurKwhLargeNumberSettings,
        label: T.ectoplanner.form.energycosts.pricegas.label
      },
      {
        key: (input) => input.energyCosts.price_el,
        enabled: _.negate(scenarioEnabled),
        ...eurKwhLargeNumberSettings,
        label: T.ectoplanner.form.energycosts.priceel.label
      },
      {
        key: (input) => input.energyCosts.price_el_uploaded,
        enabled: _.negate(scenarioEnabled),
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.energycosts.price_el_uploaded.label
      },
      {
        key: (input) => input.energyCosts.price_el_profile,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <EctoplannerProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Price}
          />
        ),
        enabled: _.negate(scenarioEnabled),
        visible: (form: EctoplannerForm) => form.energyCosts.price_el_uploaded,
        label: T.ectoplanner.form.energycosts.price_el_profile.label
      },
      {
        key: (input) => input.energyCosts.enable_feed_in_el,
        modelType: ModelType.BOOL,
        withDivider: true,
        label: T.ectoplanner.form.energycosts.enablefeedinel.label,
        helpText: T.ectoplanner.form.energycosts.enablefeedinel.helptext
      },
      spaceModel((form) => !form.energyCosts.enable_feed_in_el),
      {
        key: (input) => input.energyCosts.revenue_feed_in_el,
        modelType: ModelType.NUMBER,
        min: 0,
        max: 10,
        step: 0.01,
        unit: T.ectoplanner.units.eurkwh,
        visible: (form: EctoplannerForm) => form.energyCosts.enable_feed_in_el,
        label: T.ectoplanner.form.energycosts.revenuefeedinel.label,
        helpText: T.ectoplanner.form.energycosts.revenuefeedinel.helptext
      }
    ]
  }
]);

const gasBoilerEnabled = (form: EctoplannerForm) => form.gasBoilerBu.enabled;

export const GasBoilerBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.gasboiler,
    models: [
      {
        key: (input) => input.gasBoilerBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.gasboiler
      },
      ...maxCapModels('gasBoilerBu', gasBoilerEnabled),
      ...investmentModels(
        'gasBoilerBu',
        gasBoilerEnabled,
        T.ectoplanner.units.eurkw
      ),
      thermalEfficiencyModel('gasBoilerBu', gasBoilerEnabled)
    ]
  }
])[0];

export const GasBoilerBuTech = {
  label: T.ectoplanner.form.shared.gasboiler,
  icon: ImageBOI,
  section: GasBoilerBuSection,
  id: 'gasBoilerBu',
  enabled: (form: EctoplannerForm) => form.gasBoilerBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.gasBoilerBu.max_cap / 1000.0,
      T.ectoplanner.units.mwth
    )
};

const heatStorageBuEnabled = formObjectEnabled('heatStorageBu.enabled');

export const HeatStorageBuSection = withValidation([
  {
    label: T.ectoplanner.form.shared.heatstorage,
    models: [
      {
        key: (input) => input.heatStorageBu.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.heatstorage,
        helpText: T.ectoplanner.form.heatstoragebu.enabled.helptext
      },
      ...investmentModels(
        'heatStorageBu',
        heatStorageBuEnabled,
        T.ectoplanner.units.eurkwh
      ),
      {
        key: (input) => input.heatStorageBu.sto_loss,
        modelType: ModelType.NUMBER,
        visible: heatStorageBuEnabled,
        min: 0,
        max: 50,
        unit: T.ectoplanner.units.percentperhour,
        label: T.ectoplanner.form.shared.storageloss
      },
      ...maxCapModelsKwh('heatStorageBu', heatStorageBuEnabled)
    ]
  }
])[0];

export const HeatStorageBuTech = {
  label: T.ectoplanner.form.shared.heatstorage,
  icon: ImageHeatStorage,
  section: HeatStorageBuSection,
  id: 'heatStorageBu',
  enabled: (form: EctoplannerForm) => form.heatStorageBu.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.heatStorageBu.max_cap / 1000.0,
      T.ectoplanner.units.mwh
    )
};

const networkCostsEnabled = formObjectEnabled('network.enable_netw_costs');

export const NetworkSections = withValidation([
  {
    models: [
      {
        key: (input) => input.network.grid_t_method_option,
        modelType: ModelType.OPTIONS,
        options: gridTMethodOptions,
        label: T.ectoplanner.form.network.gridtmethod.label
      },
      /* Disable, replaced by the grid T method option
          {
            key: (input) => input.network.enable_temp_upload,
            modelType: ModelType.BOOL,
            label: T.ectoplanner.form.network.enabletempupload.label,
            withDivider: true
          },
          **/
      {
        key: (input) => input.network.temp_time_series,
        label: T.ectoplanner.form.network.temptimeseries.label,
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
          EctoplannerGridTemperatureMethodOptions.UserUpload,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <EctoplannerProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Temperature}
            energyKind={EctoplannerDemandTypes.Heating}
          />
        ),
        horizontalWeights: fileHorizontalWeights
      },
      {
        key: (input) => input.network.netw_temp_diff,
        modelType: ModelType.NUMBER,
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
            EctoplannerGridTemperatureMethodOptions.UserUpload ||
          form.network.grid_t_method_option ===
            EctoplannerGridTemperatureMethodOptions.Suggestion,
        min: 2,
        max: 20,
        unit: T.ectoplanner.units.k,
        label: T.ectoplanner.form.network.netwtempdiff.label,
        helpText: T.ectoplanner.form.network.netwtempdiff.helptext
      },
      {
        key: (input) => input.network.netw_temp_warm_pipe,
        modelType: ModelType.NUMBER,
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
          EctoplannerGridTemperatureMethodOptions.Constant,
        min: 5,
        max: 60,
        unit: T.ectoplanner.units.degc,
        label: T.ectoplanner.form.network.netwtempwarmpipe.label
      },
      {
        key: (input) => input.network.netw_temp_cold_pipe,
        modelType: ModelType.NUMBER,
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
          EctoplannerGridTemperatureMethodOptions.Constant,
        min: 0,
        max: 55,
        unit: T.ectoplanner.units.degc,
        label: T.ectoplanner.form.network.netwtempcoldpipe.label,
        helpText: T.ectoplanner.form.network.netwtempcoldpipe.helptext
      },
      /* Disable, replaced by the grid T method option
          {
            key: (input) => input.network.use_grid_t_suggestion,
            modelType: ModelType.BOOL,
            label: T.ectoplanner.form.network.use_grid_t_suggestion.label
          },
          **/
      {
        key: (input) => input.network.estimated_volume,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.network.estimated_volume.label,
        helpText: T.ectoplanner.form.network.estimated_volume.helptext,
        unit: T.ectoplanner.units.m3,
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
          EctoplannerGridTemperatureMethodOptions.Suggestion
      },
      {
        key: (input) => input.network.max_grid_mean_t,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.network.max_grid_mean_t.label,
        helpText: T.ectoplanner.form.network.max_grid_mean_t.helptext,
        unit: T.ectoplanner.units.degc,
        min: 0,
        max: 50,
        step: 1,
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
          EctoplannerGridTemperatureMethodOptions.Suggestion
      },
      {
        key: (input) => input.network.min_grid_mean_t,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.network.min_grid_mean_t.label,
        helpText: T.ectoplanner.form.network.min_grid_mean_t.helptext,
        unit: T.ectoplanner.units.degc,
        min: 0,
        max: 20,
        step: 1,
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
          EctoplannerGridTemperatureMethodOptions.Suggestion
      },
      // Disabled for now, will be brought back in the future.
      // {
      //   key: (input) => input.network.diversityFactorLowEx,
      //   modelType: ModelType.NUMBER,
      //   label: T.ectoplanner.form.network.diversityfactor.label,
      //   helpText: T.ectoplanner.form.network.diversityfactor.helptext
      // },
      {
        key: (input) => input.network.enable_netw_costs,
        modelType: ModelType.BOOL,
        withDivider: true,
        label: T.ectoplanner.form.network.enablenetwcosts.label,
        helpText: T.ectoplanner.form.network.enablenetwcosts.helptext
      },
      {
        key: (input) => input.network.costs_earthwork,
        modelType: ModelType.NUMBER,
        visible: networkCostsEnabled,
        min: 0,
        max: 10000,
        unit: T.ectoplanner.units.eurm,
        label: T.ectoplanner.form.network.costsearthwork.label
      },
      {
        key: (input) => input.network.costs_pipes,
        modelType: ModelType.NUMBER,
        visible: networkCostsEnabled,
        min: 0,
        max: 10000,
        unit: T.ectoplanner.units.eurm,
        label: T.ectoplanner.form.network.costspipes.label
      },
      // Disabled for now, will be brought back in the future.
      // {
      //   key: (input) => input.network.netwInsulation,
      //   modelType: ModelType.OPTIONS,
      //   label: T.ectoplanner.form.network.netwinsulation.label,
      //   helpText: T.ectoplanner.form.network.netwinsulation.helptext,
      //   options: [
      //     {
      //       label: T.ectoplanner.form.network.netwinsulation.insulated,
      //       value: 'insulated'
      //     },
      //     {
      //       label: T.ectoplanner.form.network.netwinsulation.uninsulated,
      //       value: 'uninsulated'
      //     }
      //   ]
      // },
      {
        key: (input) => input.network.groundTempOption,
        modelType: ModelType.OPTIONS,
        label: T.ectoplanner.form.network.groundtempoption.label,
        options: [
          {
            label: T.ectoplanner.form.network.groundtempoption.const,
            value: 'const'
          },
          {
            label: T.ectoplanner.form.network.groundtempoption.variable,
            value: 'variable'
          }
        ],
        visible: (form: EctoplannerForm) =>
          form.network.netwInsulation === 'uninsulated'
      },
      {
        key: (input) => input.network.tempGroundConst,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.network.tempgroundconst.label,
        helpText: T.ectoplanner.form.network.tempgroundconst.helptext,
        unit: T.ectoplanner.units.degc,
        visible: (form: EctoplannerForm) =>
          form.network.netwInsulation === 'uninsulated' &&
          form.network.groundTempOption === 'const'
      },
      {
        key: (input) => input.network.installationDepth,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.network.installationdepth.label,
        helpText: T.ectoplanner.form.network.installationdepth.helptext,
        min: 0.1,
        max: 2,
        step: 0.1,
        unit: T.ectoplanner.units.m,
        visible: (form: EctoplannerForm) =>
          form.network.netwInsulation === 'uninsulated' &&
          form.network.groundTempOption === 'variable'
      },
      {
        key: (input) => input.network.thConductivityGround,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.network.thconductivityground.label,
        helpText: T.ectoplanner.form.network.thconductivityground.helptext,
        step: 0.1,
        unit: T.ectoplanner.units.wmk,
        visible: (form: EctoplannerForm) =>
          form.network.netwInsulation === 'uninsulated'
      },
      {
        key: (input) => input.network.heatLossPipeDiameter,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.network.heatlosspipediameter.label,
        helpText: T.ectoplanner.form.network.heatlosspipediameter.helptext,
        step: 1,
        unit: T.ectoplanner.units.mm,
        visible: (form: EctoplannerForm) =>
          form.network.netwInsulation === 'uninsulated'
      },
      {
        key: (input) => input.network.network_length,
        modelType: ModelType.NUMBER,
        visible: (form: EctoplannerForm) =>
          networkCostsEnabled(form) ||
          form.network.netwInsulation === 'uninsulated',
        min: 0,
        max: 10,
        step: 0.1,
        unit: T.ectoplanner.units.km,
        label: T.ectoplanner.form.network.networklength.label,
        helpText: T.ectoplanner.form.network.networklength.helptext
      },
      {
        key: (input) => input.gridTemperatureSuggestion.initialMeanTProfile,
        modelType: ModelType.CUSTOM,
        render: (props, _model, form) => (
          <KeyValueGeneric
            keyText={T.ectoplanner.form.network.gridmeantempgraph.label}
            helpText={T.ectoplanner.form.network.gridmeantempgraph.helptext}
          >
            <EctoplannerDemandGraphs
              {...props}
              primaryGraph={
                form.gridTemperatureSuggestion?.suggestedWarmPipeTProfile
              }
              secondaryGraph={
                form.gridTemperatureSuggestion?.suggestedColdPipeTProfile
              }
              primaryColor={colors.heatingColor}
              secondaryColor={colors.coolingColor}
              primaryName={
                T.ectoplanner.form.network.suggestedwarmpipetemp.label
              }
              secondaryName={
                T.ectoplanner.form.network.suggestedcoldpipetemp.label
              }
              plotType={PlotTypes.AnnualTemperature}
            />
          </KeyValueGeneric>
        ),
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
            EctoplannerGridTemperatureMethodOptions.Suggestion &&
          form.gridTemperatureSuggestion?.initialMeanTProfile != null,
        hasError: () => false
      },
      {
        key: (input) =>
          input.gridTemperatureSuggestion.initialGridTkpis
            .weightedTAfterBalancing,
        modelType: ModelType.CUSTOM,
        render: (props, _model, form) => (
          <>
            <KeyValueGeneric
              keyText={
                T.ectoplanner.form.network.gridweightedavgtempgraph.label
              }
              helpText={
                T.ectoplanner.form.network.gridweightedavgtempgraph.helptext
              }
            >
              <EctoplannerDemandGraphs
                {...props}
                primaryGraph={
                  form.gridTemperatureSuggestion
                    ?.suggestedGridTkpisUserInputVolume?.weightedTAfterBalancing
                }
                primaryColor={colors.primary1Color}
                primaryName={
                  T.ectoplanner.form.network
                    .suggested_kpis_weighted_t_after_balancing.label
                }
                plotType={PlotTypes.AnnualTemperature}
              />
            </KeyValueGeneric>
          </>
        ),
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
            EctoplannerGridTemperatureMethodOptions.Suggestion &&
          form.gridTemperatureSuggestion?.initialGridTkpis != null,
        hasError: () => false
      },
      {
        key: (input) => input.gridTemperatureSuggestion,
        modelType: ModelType.TABLE,
        visible: (form: EctoplannerForm) =>
          form.network.grid_t_method_option ===
            EctoplannerGridTemperatureMethodOptions.Suggestion &&
          form.gridTemperatureSuggestion?.initialGridTkpis != null,
        helpText: T.ectoplanner.form.network.gridtemptablehelp,
        tableData: (_value, input) => {
          if (input.gridTemperatureSuggestion == null) {
            return { data: [], columns: [], isLoading: true };
          }

          const data = [
            {
              label: T.ectoplanner.form.network.table.gridandtankvolume,
              smallVolume: formatNumberUnitNode(
                input.gridTemperatureSuggestion.suggestedGridTkpisSmallVolume
                  .volume,
                T.ectoplanner.units.m3
              ),
              userVolume: (
                <strong>
                  {formatNumberUnitNode(
                    input.gridTemperatureSuggestion
                      .suggestedGridTkpisUserInputVolume.volume,
                    T.ectoplanner.units.m3
                  )}
                </strong>
              ),
              largeVolume: formatNumberUnitNode(
                input.gridTemperatureSuggestion.suggestedGridTkpisLargeVolume
                  .volume,
                T.ectoplanner.units.m3
              )
            },
            {
              label:
                T.ectoplanner.form.network.table
                  .activebalancingtankworkinghours,
              smallVolume:
                input.gridTemperatureSuggestion.suggestedGridTkpisSmallVolume
                  .sumBalancingHours,
              userVolume: (
                <strong>
                  {
                    input.gridTemperatureSuggestion
                      .suggestedGridTkpisUserInputVolume.sumBalancingHours
                  }
                </strong>
              ),
              largeVolume:
                input.gridTemperatureSuggestion.suggestedGridTkpisLargeVolume
                  .sumBalancingHours
            }
          ];
          const columns: DataTableColumnProps<
            ValueOfCollection<typeof data>
          >[] = [
            {
              dataKey: 'label'
            },
            {
              dataKey: 'smallVolume'
            },
            {
              dataKey: 'userVolume'
            },
            {
              dataKey: 'largeVolume'
            }
          ];

          return { data, columns, isLoading: false, disableHeader: true };
        }
      }
    ]
  }
]);

const solarThermalEnabled = (form: EctoplannerForm) =>
  form.solarThermal.enabled;

export const SolarThermalSection = withValidation([
  {
    label: T.ectoplanner.form.solarthermal.enabled.label,
    models: [
      {
        key: (input) => input.solarThermal.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.solarthermal.enabled.label
      },
      {
        key: (input) => input.solarThermal.min_area,
        modelType: ModelType.NUMBER,
        visible: solarThermalEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.m2,
        label: T.ectoplanner.form.photovoltaic.minarea.label
      },
      {
        key: (input) => input.solarThermal.max_area,
        modelType: ModelType.NUMBER,
        visible: solarThermalEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.m2,
        label: T.ectoplanner.form.photovoltaic.maxarea.label
      },
      {
        key: (input) => input.solarThermal.coll_temp,
        modelType: ModelType.NUMBER,
        visible: solarThermalEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.degc,
        label: T.ectoplanner.form.solarthermal.coll_temp.label
      },
      ...investmentModels(
        'solarThermal',
        solarThermalEnabled,
        T.ectoplanner.units.eurkwp
      ),
      {
        key: (input) => input.solarThermal.areaList,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <EctoplannerSolarAreaEditor {...props} model={model} />
        ),
        visible: solarThermalEnabled,
        label: T.ectoplanner.form.common.solararealist.label,
        hasError: (value: EctoplannerSolarAreaDefinition[]) => {
          return _.some(value, (def: EctoplannerSolarAreaDefinition) => {
            def.area === null || def.azimuth == null || def.elevation == null;
          });
        }
      }
    ]
  }
])[0];

export const SolarThermalTech = {
  label: T.ectoplanner.form.solarthermal.enabled.label,
  icon: ImageSTC,
  section: SolarThermalSection,
  id: 'solarThermal',
  enabled: (form: EctoplannerForm) => form.solarThermal.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(form.solarThermal.max_area, T.ectoplanner.units.m2)
};

const photovoltaicEnabled = (form: EctoplannerForm) =>
  form.photovoltaic.enabled;

export const PhotovoltaicSection = withValidation([
  {
    label: T.ectoplanner.form.photovoltaic.enabled.label,
    models: [
      {
        key: (input) => input.photovoltaic.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.photovoltaic.enabled.label
      },
      {
        key: (input) => input.photovoltaic.pvEfficiency,
        modelType: ModelType.NUMBER,
        visible: photovoltaicEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.percent,
        label: T.ectoplanner.form.photovoltaic.efficiency.label
      },
      {
        key: (input) => input.photovoltaic.min_area,
        modelType: ModelType.NUMBER,
        visible: photovoltaicEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.m2,
        label: T.ectoplanner.form.photovoltaic.minarea.label
      },
      {
        key: (input) => input.photovoltaic.max_area,
        modelType: ModelType.NUMBER,
        visible: photovoltaicEnabled,
        min: 0,
        step: 10,
        unit: T.ectoplanner.units.m2,
        label: T.ectoplanner.form.photovoltaic.maxarea.label
      },
      {
        key: (input) => input.photovoltaic.pvProfileUploaded,
        modelType: ModelType.BOOL,
        visible: photovoltaicEnabled,
        label: T.ectoplanner.form.photovoltaic.pvprofileuploaded.label
      },
      {
        key: (input) => input.photovoltaic.pvProfile,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <EctoplannerProfileEditor
            {...props}
            energyKind={EctoplannerDemandTypes.Electricity}
            model={model}
          />
        ),
        label: T.ectoplanner.form.photovoltaic.pvprofile.label,
        optional: true,
        visible: (form: EctoplannerForm) =>
          photovoltaicEnabled(form) && form.photovoltaic.pvProfileUploaded
      },
      ...investmentModels(
        'photovoltaic',
        photovoltaicEnabled,
        T.ectoplanner.units.eurkwp
      ),
      {
        key: (input) => input.photovoltaic.areaList,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <EctoplannerSolarAreaEditor {...props} model={model} />
        ),
        visible: photovoltaicEnabled,
        label: T.ectoplanner.form.common.solararealist.label,
        hasError: (value: EctoplannerSolarAreaDefinition[]) => {
          return _.some(value, (def: EctoplannerSolarAreaDefinition) => {
            def.area === null || def.azimuth == null || def.elevation == null;
          });
        }
      }
    ]
  }
])[0];

export const PhotovoltaicTech = {
  label: T.ectoplanner.form.photovoltaic.enabled.label,
  icon: ImagePV,
  section: PhotovoltaicSection,
  id: 'photovoltaic',
  enabled: (form: EctoplannerForm) => form.photovoltaic.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(form.photovoltaic.max_area, T.ectoplanner.units.m2)
};

const wasteCoolingEnabled = formObjectEnabled('wasteCooling.enabled');

export const WasteCoolingSection = withValidation([
  {
    label: T.ectoplanner.form.shared.ambientcoldsource,
    models: [
      {
        key: (input) => input.wasteCooling.enabled,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.shared.ambientcoldsource
      },
      {
        key: (input) => input.wasteCooling.max_COP,
        modelType: ModelType.NUMBER,
        min: 1,
        max: 100,
        step: 1,
        visible: wasteCoolingEnabled,
        label: T.ectoplanner.form.shared.maximumcop
      },
      {
        key: (input) => input.wasteCooling.price,
        modelType: ModelType.NUMBER,
        visible: wasteCoolingEnabled,
        min: 0,
        step: 0.001,
        unit: T.ectoplanner.units.eurkwh,
        label: T.ectoplanner.form.shared.price
      },
      {
        key: (input) => input.wasteCooling.enable_temp_upload,
        modelType: ModelType.BOOL,
        label: T.ectoplanner.form.wasteheat.enable_temp_upload.label,
        visible: wasteCoolingEnabled
      },
      {
        key: (input) => input.wasteCooling.temp_time_series,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <EctoplannerProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Temperature}
            energyKind={EctoplannerDemandTypes.Cooling}
          />
        ),
        label: T.ectoplanner.form.wasteheat.temp_time_series.label,
        visible: (form: EctoplannerForm) =>
          wasteCoolingEnabled(form) && form.wasteCooling.enable_temp_upload,
        errorText: (_value: unknown, form: EctoplannerForm) => {
          if (
            form.wasteCooling.enable_temp_upload &&
            (form.wasteCooling.temp_time_series == null ||
              form.wasteCooling.temp_time_series.length === 0)
          ) {
            return T.ectoplanner.validationerror.notset;
          }

          return null;
        }
      },
      ...potentialTimeSeriesModels(
        'wasteCooling',
        wasteCoolingEnabled,
        T.ectoplanner.form.wastecooling.enablepotentialupload.label,
        EctoplannerDemandTypes.Cooling
      ),
      {
        key: (input) => input.wasteCooling.available_power,
        modelType: ModelType.NUMBER,
        visible: (form: EctoplannerForm) =>
          wasteCoolingEnabled(form) &&
          form.wasteCooling.enable_potential_upload === false,
        min: 0,
        unit: T.ectoplanner.units.kw,
        label: T.ectoplanner.form.wastecooling.availablepower.label,
        helpText: T.ectoplanner.form.shared.thermalpoweravailableovertheyear
      },
      {
        key: (input) => input.wasteCooling.enable_chiller,
        modelType: ModelType.BOOL,
        withDivider: true,
        visible: wasteCoolingEnabled,
        label: T.ectoplanner.form.wastecooling.enablechiller.label
      },
      {
        key: (input) => input.wasteCooling.cop_chiller,
        modelType: ModelType.NUMBER,
        visible: (form: EctoplannerForm) =>
          wasteCoolingEnabled(form) && form.wasteCooling.enable_chiller,
        min: 0,
        step: 0.1,
        label: T.ectoplanner.form.wastecooling.copchiller.label
      },
      COPCarnotEfficiencyModel('wasteCooling', wasteCoolingEnabled, null)
    ]
  }
])[0];

export const WasteCoolingTech = {
  label: T.ectoplanner.form.shared.ambientcoldsource,
  icon: ImageAmbient_cold,
  section: WasteCoolingSection,
  id: 'wasteCooling',
  enabled: (form: EctoplannerForm) => form.wasteCooling.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.wasteCooling.available_power / 1000.0,
      T.ectoplanner.units.mw
    )
};

export const ReferenceSystemSections = withValidation([
  {
    wrapContent: true,
    label: T.ectoplanner.form.referencesystem.title,
    models: [
      {
        key: (input) => input.referenceSystem.ashp_cop_space_heat,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.ashp_cop_space_heat,
        helpText:
          T.ectoplanner.form.referencesystem.tooltips.ashp_cop_space_heat
      },
      {
        key: (input) => input.referenceSystem.ashp_cop_dhw,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.ashp_cop_dhw,
        helpText: T.ectoplanner.form.referencesystem.tooltips.ashp_cop_dhw
      },
      {
        key: (input) => input.referenceSystem.inv_ashp,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.inv_ashp,
        unit: T.ectoplanner.units.eurkwth
      },
      {
        key: (input) => input.referenceSystem.gshp_cop_space_heat,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.gshp_cop_space_heat,
        helpText:
          T.ectoplanner.form.referencesystem.tooltips.gshp_cop_space_heat
      },
      {
        key: (input) => input.referenceSystem.gshp_cop_dhw,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.gshp_cop_dhw,
        helpText: T.ectoplanner.form.referencesystem.tooltips.gshp_cop_dhw
      },
      {
        key: (input) => input.referenceSystem.inv_gshp,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.inv_gshp,
        unit: T.ectoplanner.units.eurkwth
      },
      {
        key: (input) => input.referenceSystem.chiller_cop,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.chiller_cop,
        helpText: T.ectoplanner.form.referencesystem.tooltips.chiller_cop
      },
      {
        key: (input) => input.referenceSystem.inv_chiller,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.inv_chiller,
        helpText: T.ectoplanner.form.referencesystem.tooltips.inv_chiller,
        unit: T.ectoplanner.units.eurkwth
      },
      {
        key: (input) => input.referenceSystem.boiler_eff,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.boiler_eff,
        helpText: T.ectoplanner.form.referencesystem.tooltips.boiler_eff,
        unit: T.ectoplanner.units.percent
      },
      {
        key: (input) => input.referenceSystem.inv_gas_boiler,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.inv_gas_boiler,
        unit: T.ectoplanner.units.eurkwth
      },
      {
        key: (input) => input.referenceSystem.central_gas_boiler_eta_th,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.central_gas_boiler_eta_th,
        helpText:
          T.ectoplanner.form.referencesystem.tooltips.central_gas_boiler_eta_th,
        unit: T.ectoplanner.units.percent
      },
      {
        key: (input) => input.referenceSystem.central_gas_boiler_om,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.central_gas_boiler_om,
        helpText:
          T.ectoplanner.form.referencesystem.tooltips.central_gas_boiler_om,
        unit: T.ectoplanner.units.percent
      },
      {
        key: (input) => input.referenceSystem.central_gas_boiler_inv,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.central_gas_boiler_inv,
        helpText:
          T.ectoplanner.form.referencesystem.tooltips.central_gas_boiler_inv,
        unit: T.ectoplanner.units.eurkwth
      },
      {
        key: (input) => input.referenceSystem.om_costs,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.om_costs,
        helpText: T.ectoplanner.form.referencesystem.tooltips.om_costs,
        unit: T.ectoplanner.units.percent
      },
      {
        key: (input) => input.referenceSystem.netw_losses,
        modelType: ModelType.NUMBER,
        label: T.ectoplanner.form.referencesystem.netw_losses,
        helpText: T.ectoplanner.form.referencesystem.tooltips.netw_losses,
        unit: T.ectoplanner.units.percent
      }
    ]
  }
]);

export const WasteHeatSection = (prefix: 'wasteHeat' | 'wasteHeat2') => {
  const wasteHeatEnabled = formObjectEnabled(prefix + '.enabled');
  const label =
    prefix === 'wasteHeat'
      ? T.ectoplanner.form.shared.ambientheatsource
      : T.ectoplanner.form.shared.wasteheatsource;

  return withValidation([
    {
      label,
      models: [
        {
          key: (input) => input[prefix].enabled,
          modelType: ModelType.BOOL,
          label
        },
        {
          key: (input) => input[prefix].max_COP,
          modelType: ModelType.NUMBER,
          min: 1,
          max: 100,
          step: 1,
          visible: wasteHeatEnabled,
          label: T.ectoplanner.form.shared.maximumcop
        },
        {
          key: (input) => input[prefix].price,
          modelType: ModelType.NUMBER,
          visible: wasteHeatEnabled,
          min: 0,
          step: 0.001,
          unit: T.ectoplanner.units.eurkwh,
          label: T.ectoplanner.form.shared.price
        },
        {
          key: (input) => input[prefix].enable_temp_upload,
          modelType: ModelType.BOOL,
          label: T.ectoplanner.form.wasteheat.enable_temp_upload.label,
          visible: wasteHeatEnabled
        },
        {
          key: (input) => input[prefix].temp_time_series,
          modelType: ModelType.CUSTOM,
          render: (props, model) => (
            <EctoplannerProfileEditor
              {...props}
              model={model}
              profileType={EctoplannerProfileTypes.Temperature}
              energyKind={EctoplannerDemandTypes.Heating}
            />
          ),
          label: T.ectoplanner.form.wasteheat.temp_time_series.label,
          visible: (form: EctoplannerForm) =>
            wasteHeatEnabled(form) && form[prefix].enable_temp_upload,
          errorText: (_value: unknown, form: EctoplannerForm) => {
            const object = form[prefix];
            if (
              object.enable_temp_upload &&
              (object.temp_time_series == null ||
                object.temp_time_series.length === 0)
            ) {
              return T.ectoplanner.validationerror.notset;
            }

            return null;
          }
        },
        ...potentialTimeSeriesModels(
          prefix,
          wasteHeatEnabled,
          T.ectoplanner.form.wasteheat.enablepotentialupload.label,
          EctoplannerDemandTypes.Heating
        ),
        {
          key: (input) => input[prefix].available_power,
          modelType: ModelType.NUMBER,
          visible: (form: EctoplannerForm) =>
            wasteHeatEnabled(form) &&
            form[prefix].enable_potential_upload === false,
          min: 0,
          unit: T.ectoplanner.units.kw,
          label: T.ectoplanner.form.wasteheat.availablepower.label,
          helpText: T.ectoplanner.form.shared.thermalpoweravailableovertheyear
        },
        {
          key: (input) => input[prefix].enable_hp,
          modelType: ModelType.BOOL,
          withDivider: true,
          visible: wasteHeatEnabled,
          label: T.ectoplanner.form.wasteheat.enablehp.label
        },
        COPCarnotEfficiencyModel(
          prefix,
          (form) => wasteHeatEnabled(form) && form[prefix].enable_hp,
          null
        ),
        {
          key: (input) => input[prefix].cop_hp,
          visible: (form: EctoplannerForm) =>
            wasteHeatEnabled(form) && form[prefix].enable_hp,
          modelType: ModelType.NUMBER,
          min: 1,
          step: 0.1,
          label: T.ectoplanner.form.wasteheat.cophp.label
        }
      ]
    }
  ])[0];
};

export const WasteHeat1Tech = {
  label: T.ectoplanner.form.shared.ambientheatsource,
  icon: ImageAmbient_heat,
  section: WasteHeatSection('wasteHeat'),
  id: 'wasteHeat',
  enabled: (form: EctoplannerForm) => form.wasteHeat.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.wasteHeat.available_power / 1000.0,
      T.ectoplanner.units.mw
    )
};

export const WasteHeat2Tech = {
  label: T.ectoplanner.form.shared.wasteheatsource,
  icon: ImageAmbient_heat,
  section: WasteHeatSection('wasteHeat2'),
  id: 'wasteHeat2',
  enabled: (form: EctoplannerForm) => form.wasteHeat2.enabled,
  capacityText: (form: EctoplannerForm) =>
    formatNumberUnitNode(
      form.wasteHeat2.available_power / 1000.0,
      T.ectoplanner.units.mw
    )
};

// Since the location editing is a bit complex with file selection and so on, we only use these
// sections when validating the full form and not for UI. The form logic and UI is contained in
// EditEctoplannerLocation.
export const LocationSections = withValidation([
  {
    label: T.ectoplanner.location.title,
    models: [
      {
        key: (input) => input.location.city.name,
        label: T.ectoplanner.location.city,
        modelType: ModelType.TEXT
      },
      {
        key: (input) => input.location.country.name,
        modelType: ModelType.TEXT,
        label: T.ectoplanner.location.country
      }
    ]
  }
]);

// NPRO FORM
const lowexCoolingOptions: GenericSelectOption<string>[] = [
  {
    label: T.ectoplanner.form.building.params.lowexdrc.label,
    value: 'lowExDrc'
  },
  {
    label: T.ectoplanner.form.building.params.lowexcc.label,
    value: 'lowExCc'
  }
];

const periodStartOptions: GenericSelectOption<number>[] = monthsConst.map(
  (monthIndex) => {
    return {
      label: moment()
        .set('month', monthIndex - 1)
        .startOf('month')
        .format('MMMM D'),
      value: monthIndex
    };
  }
);

const periodEndOptions: GenericSelectOption<number>[] = monthsConst.map(
  (monthIndex) => {
    return {
      label: moment()
        .set('month', monthIndex - 1)
        .endOf('month')
        .format('MMMM D'),
      value: monthIndex
    };
  }
);

function floorArea(form: EctoplannerForm, buildingIndex: number) {
  return form.buildings[buildingIndex].params.floorArea;
}

type EnergyKindVisibilityParam =
  | 'checkCalcProcessCool'
  | 'checkCalcEmob'
  | 'checkCalcSpaceHeat'
  | 'checkCalcDhw'
  | 'checkCalcPlugLoads'
  | 'checkCalcSpaceCool';

const calcShareDhw = (annDemTotHeat: number, annDemTotDhw: number) => {
  return Math.round((annDemTotDhw / (annDemTotHeat + annDemTotDhw)) * 100.0);
};

type ProfileKey =
  | 'spaceHeatProfile'
  | 'emobProfile'
  | 'dhwProfile'
  | 'plugLoadsProfile'
  | 'spaceCoolProfile'
  | 'processCoolProfile';

const energyKindModels = (
  title: React.ReactNode,
  suffix: 'Heat' | 'Cool' | 'Dhw' | 'ProcessCool' | 'PlugLoads',
  buildingIndex: number,
  visibilityKey: EnergyKindVisibilityParam,
  customTimeSeriesKey: ProfileKey
): EctoplannerModelDefinition[] => {
  const visFunction = (form: EctoplannerForm) =>
    form.buildings[buildingIndex].params[visibilityKey] &&
    !form.buildings[buildingIndex].params[customTimeSeriesKey];
  let energyKind: EctoplannerDemandType = EctoplannerDemandTypes.Heating;

  if (suffix === 'Cool' || suffix === 'ProcessCool') {
    energyKind = EctoplannerDemandTypes.Cooling;
  } else if (suffix === 'PlugLoads') {
    energyKind = EctoplannerDemandTypes.Electricity;
  }

  return [
    {
      key: (input) => input.buildings[buildingIndex].params[visibilityKey],
      modelType: ModelType.BOOL,
      label: T.format(
        T.ectoplanner.form.building.params.enableformat,
        title
      ).join(''),
      helpText: T.ectoplanner.form.building.params.enablehelptext
    },
    {
      key: (input) =>
        input.buildings[buildingIndex].params[customTimeSeriesKey],
      modelType: ModelType.CUSTOM,
      render: (props, model) => (
        <EctoplannerProfileEditor
          {...props}
          model={model}
          energyKind={energyKind}
        />
      ),
      label: T.ectoplanner.form.building.params.customprofile.label,
      helpText: T.ectoplanner.form.building.params.customprofile.helptext,
      optional: true,
      visible: (form: EctoplannerForm) =>
        form.buildings[buildingIndex].params[visibilityKey]
    },
    {
      key: (input) =>
        input.buildings[buildingIndex].params[makeKey('annDemSpec', suffix)],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 100000,
      step: 1,
      unit: T.ectoplanner.units.kwhm2year,
      label: T.ectoplanner.form.building.params.anndemspec.label,
      helpText: T.ectoplanner.form.building.params.anndemspec.helptext,
      visible: visFunction,
      onDidUpdate: (_key: string[], value: number, form: EctoplannerForm) => {
        const area = floorArea(form, buildingIndex);
        return [
          [
            (input) =>
              input.buildings[buildingIndex].params[
                makeKey('annDemTot', suffix)
              ],
            Math.round((area * value) / 1000.0)
          ]
        ];
      }
    },
    {
      key: (input) =>
        input.buildings[buildingIndex].params[makeKey('annDemTot', suffix)],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 100000,
      step: 1,
      unit: T.ectoplanner.units.mwhyear,
      label: T.ectoplanner.form.building.params.anndemtot.label,
      helpText: T.ectoplanner.form.building.params.anndemtot.helptext,
      visible: visFunction,
      onDidUpdate: (_key: string[], value: number, form: EctoplannerForm) => {
        const area = floorArea(form, buildingIndex);
        const ret: [(input: EctoplannerForm) => unknown, unknown][] = [
          [
            (input) =>
              input.buildings[buildingIndex].params[
                makeKey('annDemSpec', suffix)
              ],
            area === 0.0 ? 0.0 : roundTwoDecimals((value * 1000.0) / area)
          ]
        ];

        if (suffix === 'Dhw') {
          ret.push([
            (input) => input.buildings[buildingIndex].params.shareDhw,
            calcShareDhw(
              form.buildings[buildingIndex].params.annDemTotHeat,
              value
            )
          ]);
        } else if (suffix === 'Heat') {
          ret.push([
            (input) => input.buildings[buildingIndex].params.shareDhw,
            calcShareDhw(
              value,
              form.buildings[buildingIndex].params.annDemTotDhw
            )
          ]);
        }

        return ret;
      }
    }
  ];
};

const calculationSeasonModels = (
  suffix: 'Heating' | 'Cooling',
  buildingIndex: number,
  visible: (form: EctoplannerForm) => boolean
): EctoplannerModelDefinition[] => {
  const loweredSuffix: Lowercase<typeof suffix> =
    suffix.toLowerCase() as Lowercase<typeof suffix>;

  return [
    {
      key: (input) =>
        input.buildings[buildingIndex].params[
          makeKey3('check', suffix, 'Period')
        ],
      modelType: ModelType.BOOL,
      visible: visible,
      label: T.ectoplanner.form.building.params.setseason.label,
      helpText: T.ectoplanner.form.building.params.setseason.helptext
    },
    {
      key: (input) =>
        input.buildings[buildingIndex].params[
          makeKey(loweredSuffix, 'PeriodBegin')
        ],
      modelType: ModelType.OPTIONS,
      visible: (form: EctoplannerForm) =>
        visible(form) &&
        _.get(
          form.buildings[buildingIndex].params,
          'check' + suffix + 'Period'
        ),
      options: periodStartOptions,
      label: T.ectoplanner.form.building.params.seasonbegin.label,
      helpText:
        suffix === 'Heating'
          ? T.ectoplanner.form.building.params.seasonbegin.helptextheating
          : T.ectoplanner.form.building.params.seasonbegin.helptextcooling
    },
    {
      key: (input) =>
        input.buildings[buildingIndex].params[
          makeKey(loweredSuffix, 'PeriodEnd')
        ],
      modelType: ModelType.OPTIONS,
      visible: (form: EctoplannerForm) =>
        visible(form) &&
        _.get(
          form.buildings[buildingIndex].params,
          'check' + suffix + 'Period'
        ),
      options: periodEndOptions,
      label: T.ectoplanner.form.building.params.seasonend.label,
      helpText:
        suffix === 'Heating'
          ? T.ectoplanner.form.building.params.seasonend.helptextheating
          : T.ectoplanner.form.building.params.seasonend.helptextcooling
    }
  ];
};

const heatPumpVars = (
  category: 'SpaceHeat' | 'Dhw' | 'Cool' | 'Transversal'
) => {
  if (category === 'SpaceHeat') {
    return {
      besLowExCopOptions: 'besLowExCopSpaceHeat',
      besLowExCop: 'besLowExHpSpaceHeatCop',
      hpType: 'heatPumpTypeCopSpaceHeat',
      supplyTemp: 'besLowExHpSpaceHeatSupplyTemp',
      carnotEta: 'besLowExHpSpaceHeatCarnotEta',
      carnotSliding: 'besLowExHpSpaceHeatSupplyTempSliding',
      supplyTempText:
        T.ectoplanner.form.building.params.supplytempheatpump.label,
      supplyTempHelp:
        T.ectoplanner.form.building.params.supplytempheatpump.helptext,
      carnotEtaHelpText: T.ectoplanner.form.building.params.carnotetahp.helptext
    } as const;
  } else if (category === 'Dhw') {
    return {
      besLowExCopOptions: 'besLowExCopDhw',
      besLowExCop: 'besLowExHpDhwCop',
      hpType: 'heatPumpTypeCopDhw',
      supplyTemp: 'besLowExHpDhwSupplyTemp',
      carnotEta: 'besLowExHpDhwCarnotEta',
      carnotSliding: 'besLowExHpDhwSupplyTempSliding',
      supplyTempText: T.ectoplanner.form.building.params.supplytempdhw.label,
      supplyTempHelp: T.ectoplanner.form.building.params.supplytempdhw.helptext,
      carnotEtaHelpText: T.ectoplanner.form.building.params.carnotetahp.helptext
    } as const;
  } else if (category === 'Cool') {
    return {
      besLowExCopOptions: 'besLowExCopCool',
      besLowExCop: 'besLowExCcCop',
      hpType: 'chillerTypeCopCool',
      supplyTemp: 'besLowExCcSupplyTemp',
      carnotEta: 'besLowExCcCarnotEta',
      carnotSliding: 'besLowExCcSupplyTempSliding',
      supplyTempText:
        T.ectoplanner.form.building.params.supplytempchiller.label,
      supplyTempHelp:
        T.ectoplanner.form.building.params.supplytempchiller.helptext,
      carnotEtaHelpText:
        T.ectoplanner.form.building.params.carnotetachiller.helptext
    } as const;
  }
};

const besHeatpumpModels = (
  category: 'SpaceHeat' | 'Dhw' | 'Cool' | 'Transversal',
  buildingIndex: number,
  sectionVisibilityFunction: (form: EctoplannerForm) => boolean = _.stubTrue
): EctoplannerModelDefinition[] => {
  const hpVars = heatPumpVars(category);

  let supplyTempText: string;
  let supplyTempHelp: string;
  let carnotEtaHelpText: string;

  if (category === 'SpaceHeat') {
    supplyTempText =
      T.ectoplanner.form.building.params.supplytempheatpump.label;
    supplyTempHelp =
      T.ectoplanner.form.building.params.supplytempheatpump.helptext;
    carnotEtaHelpText = T.ectoplanner.form.building.params.carnotetahp.helptext;
  } else if (category === 'Dhw') {
    supplyTempText = T.ectoplanner.form.building.params.supplytempdhw.label;
    supplyTempHelp = T.ectoplanner.form.building.params.supplytempdhw.helptext;
    carnotEtaHelpText = T.ectoplanner.form.building.params.carnotetahp.helptext;
  } else if (category === 'Cool') {
    supplyTempText = T.ectoplanner.form.building.params.supplytempchiller.label;
    supplyTempHelp =
      T.ectoplanner.form.building.params.supplytempchiller.helptext;
    carnotEtaHelpText =
      T.ectoplanner.form.building.params.carnotetachiller.helptext;
  }

  return _.compact([
    {
      key: (input) =>
        input.buildings[buildingIndex].params[hpVars.besLowExCopOptions],
      label: T.ectoplanner.form.building.params.cop.label,
      helpText:
        T.ectoplanner.form.building.params.energysystem.copoptions.helptext,
      modelType: ModelType.OPTIONS,
      options: copOptions,
      visible: sectionVisibilityFunction
    },
    {
      key: (input) => input.buildings[buildingIndex].params[hpVars.besLowExCop],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 40,
      step: 1,
      visible: (form: EctoplannerForm) =>
        form.buildings[buildingIndex].params[hpVars.besLowExCopOptions] ===
          'const' && sectionVisibilityFunction(form),
      label: T.ectoplanner.form.building.params.beslowexhpcop.label
    },
    {
      key: (input) => input.buildings[buildingIndex].params[hpVars.hpType],
      label: T.ectoplanner.form.building.params.productdata.label,
      modelType: ModelType.OPTIONS,
      options: heatPumpOptions,
      hasError: (value: string, form: EctoplannerForm) => {
        if (
          form.buildings[buildingIndex].params[hpVars.besLowExCopOptions] !==
          'productData'
        ) {
          return false;
        }

        return (
          value == null ||
          heatPumpOptions.find((x) => x.value === value) == null
        );
      },
      visible: (form: EctoplannerForm) =>
        form.buildings[buildingIndex].params[hpVars.besLowExCopOptions] ===
          'productData' && sectionVisibilityFunction(form)
    },
    {
      key: (input) => input.buildings[buildingIndex].params[hpVars.supplyTemp],
      label: supplyTempText,
      modelType: ModelType.NUMBER,
      min: 0,
      max: 400,
      step: 1,
      unit: T.ectoplanner.units.degc,
      useTooltipHelpTexts: false, // Need to show the COP calculation inline.
      visible: (form: EctoplannerForm) =>
        (form.buildings[buildingIndex].params[hpVars.besLowExCopOptions] ===
          'productData' ||
          form.buildings[buildingIndex].params[hpVars.besLowExCopOptions] ===
            'carnot') &&
        sectionVisibilityFunction(form),
      helpText: (_value: unknown, form: EctoplannerForm) => {
        const type =
          form.buildings[buildingIndex].params[hpVars.besLowExCopOptions];

        let warmTemp = form.buildings[buildingIndex].params[hpVars.supplyTemp];
        let coldTemp = mainState.meanNetwTemp;

        if (category === 'Cool') {
          const temp = coldTemp;
          coldTemp = warmTemp;
          warmTemp = temp;
        }

        let cop =
          type === 'productData'
            ? copData.getProductDataCop(
                warmTemp,
                coldTemp,
                form.buildings[buildingIndex].params[hpVars.hpType],
                category === 'Cool' ? 'chiller' : 'heat_pump'
              )
            : calcBes.calcCarnotCop(
                warmTemp,
                coldTemp,
                form.buildings[buildingIndex].params[hpVars.carnotEta] / 100.0
              );

        if (cop == null) {
          return '';
        }

        if (category === 'Cool') {
          // For some reason 1 is subtracted for this COP value in the commercial engine website - do it here too so they align
          cop -= 1;
        }

        return (
          <>
            <span>{supplyTempHelp}</span>
            <br />
            <br />
            {!isNaN(cop) &&
              T.format(
                T.ectoplanner.form.building.params.productdata.formatstring,
                cop.toFixed(1),
                coldTemp,
                warmTemp
              )}
          </>
        );
      }
    },
    category === 'SpaceHeat' && {
      key: (input) =>
        input.buildings[buildingIndex].params
          .besLowExHpSpaceHeatSupplyTempSliding,
      label: T.ectoplanner.form.building.params.considercurve.label,
      helpText: T.ectoplanner.form.building.params.considercurve.helptext,
      modelType: ModelType.BOOL,
      visible: (form: EctoplannerForm) =>
        (form.buildings[buildingIndex].params[hpVars.besLowExCopOptions] ===
          'productData' ||
          form.buildings[buildingIndex].params[hpVars.besLowExCopOptions] ===
            'carnot') &&
        sectionVisibilityFunction(form)
    },
    {
      key: (input) => input.buildings[buildingIndex].params[hpVars.carnotEta],
      label: T.ectoplanner.form.building.params.carnoteta.label,
      helpText: carnotEtaHelpText,
      modelType: ModelType.NUMBER,
      min: 0,
      max: 100,
      step: 1,
      unit: T.ectoplanner.units.percent,
      visible: (form: EctoplannerForm) =>
        form.buildings[buildingIndex].params[hpVars.besLowExCopOptions] ===
          'carnot' && sectionVisibilityFunction(form)
    }
  ]);
};

const calculationPeakDemModels = (
  suffix: 'Heat' | 'Cool',
  buildingIndex: number,
  visibilityKey: EnergyKindVisibilityParam,
  customTimeSeriesKey: ProfileKey
): EctoplannerModelDefinition[] => {
  const visFunction = (form: EctoplannerForm) =>
    form.buildings[buildingIndex].params[visibilityKey] &&
    !form.buildings[buildingIndex].params[customTimeSeriesKey];

  return [
    {
      key: (input) =>
        input.buildings[buildingIndex].params[
          makeKey('checkPeakKnown', suffix)
        ],
      modelType: ModelType.BOOL,
      label: T.ectoplanner.form.building.params.setpeakload.label,
      helpText: T.ectoplanner.form.building.params.setpeakload.helptext,
      visible: visFunction
    },
    {
      key: (input) =>
        input.buildings[buildingIndex].params[makeKey('peakDemSpec', suffix)],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 100000,
      step: 1,
      unit: T.ectoplanner.units.wm2,
      visible: (form: EctoplannerForm) =>
        _.get(
          form.buildings[buildingIndex].params,
          'checkPeakKnown' + suffix
        ) && visFunction(form),
      label: T.ectoplanner.form.building.params.peakdemspec.label,
      helpText: T.ectoplanner.form.building.params.peakdemspec.helptext,
      onDidUpdate: (
        _key: string[],
        peakDemSpec: number,
        form: EctoplannerForm
      ) => {
        const area = floorArea(form, buildingIndex);
        const annDemTot =
          form.buildings[buildingIndex].params[makeKey('annDemTot', suffix)];
        const newPeakDemTot = Math.round((area * peakDemSpec) / 1000.0);

        return [
          [
            (input) =>
              input.buildings[buildingIndex].params[
                makeKey('peakDemTot', suffix)
              ],
            newPeakDemTot
          ],
          [
            (input) =>
              input.buildings[buildingIndex].params[
                makeKey('fullLoadHrs', suffix)
              ],
            newPeakDemTot === 0.0
              ? 0.0
              : Math.round((annDemTot / newPeakDemTot) * 1e3)
          ]
        ];
      }
    },
    {
      key: (input) =>
        input.buildings[buildingIndex].params[makeKey('peakDemTot', suffix)],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 100000,
      step: 1,
      unit: T.ectoplanner.units.kw,
      visible: (form: EctoplannerForm) =>
        _.get(
          form.buildings[buildingIndex].params,
          'checkPeakKnown' + suffix
        ) && visFunction(form),
      label: T.ectoplanner.form.building.params.peakdemtot.label,
      helpText: T.ectoplanner.form.building.params.peakdemtot.helptext,
      onDidUpdate: (
        _key: string[],
        peakDemTot: number,
        form: EctoplannerForm
      ) => {
        const area = floorArea(form, buildingIndex);
        const annDemTot =
          form.buildings[buildingIndex].params[makeKey('annDemTot', suffix)];

        return [
          [
            (input) =>
              input.buildings[buildingIndex].params[
                makeKey('peakDemSpec', suffix)
              ],
            area === 0.0 ? 0.0 : roundTwoDecimals((peakDemTot * 1000.0) / area)
          ],
          [
            (input) =>
              input.buildings[buildingIndex].params[
                makeKey('fullLoadHrs', suffix)
              ],
            annDemTot === 0.0 ? 0.0 : Math.round((annDemTot / peakDemTot) * 1e3)
          ]
        ];
      }
    },
    {
      key: (input) =>
        input.buildings[buildingIndex].params[makeKey('fullLoadHrs', suffix)],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 100000,
      step: 1,
      unit: T.ectoplanner.units.hyear,
      visible: (form: EctoplannerForm) =>
        _.get(
          form.buildings[buildingIndex].params,
          'checkPeakKnown' + suffix
        ) && visFunction(form),
      label: T.ectoplanner.form.building.params.fullloadhours.label,
      helpText: T.ectoplanner.form.building.params.fullloadhours.helptext,
      onDidUpdate: (
        _key: string[],
        fullLoadHrs: number,
        form: EctoplannerForm
      ) => {
        const area = floorArea(form, buildingIndex);
        const annDemTot =
          form.buildings[buildingIndex].params[makeKey('annDemTot', suffix)];
        const peakDemTot =
          fullLoadHrs === 0.0
            ? 0.0
            : Math.round((annDemTot / fullLoadHrs) * 1e3);
        const peakDemSpec =
          area === 0.0 ? 0.0 : roundTwoDecimals((peakDemTot * 1000.0) / area);

        return [
          [
            (input) =>
              input.buildings[buildingIndex].params[
                makeKey('peakDemSpec', suffix)
              ],
            peakDemSpec
          ],
          [
            (input) =>
              input.buildings[buildingIndex].params[
                makeKey('peakDemTot', suffix)
              ],
            peakDemTot
          ]
        ];
      }
    }
  ];
};

function calcAnnDemTot(totalFloorArea: number, annDemSpec: number) {
  return Math.round((totalFloorArea * annDemSpec) / 1000.0);
}

function calcPeakDemTot(totalFloorArea: number, peakDemSpec: number) {
  return Math.round((totalFloorArea * peakDemSpec) / 1000.0);
}

function calcFullLoadHours(peakDemTot: number, annDemTot: number) {
  return peakDemTot === 0.0 ? 0.0 : Math.round((annDemTot / peakDemTot) * 1e3);
}

export const getBuildingNproSections = (
  buildings: EctoplannerFormBuilding[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cityData: any
) => {
  const buildingTypeOptions: GenericSelectOption<string>[] = Object.keys(
    cityData ?? {}
  )
    .filter((key: string) => key !== 'latitude' && key !== 'longitude') // For some reason Marco includes these
    .sort()
    .map((key: string) => ({
      label: key,
      value: key
    }));

  const buildingSubtypeOptions: Record<string, GenericSelectOption<string>[]> =
    _.mapValues(cityData ?? {}, (val) => {
      return Object.keys(val).map((subtype: string) => ({
        label: subtype,
        value: subtype
      }));
    });

  const allSections: EctoplannerSectionType[][] = buildings.map(
    (building: EctoplannerFormBuilding, buildingIndex: number) =>
      withValidation(
        [
          {
            label: isNullOrWhitespace(building.name) ? ' ' : building.name,
            subSectionsStyle: ModelFormSectionStyle.SEGMENT_CONTROL,
            hideLabel: true,
            sections: [
              {
                label: T.ectoplanner.form.building.params.sections.building,
                wrapContent: false,
                models: [
                  {
                    key: (input) => input.buildings[buildingIndex].name,
                    modelType: ModelType.TEXT,
                    label: T.ectoplanner.form.shared.buildingname
                  },
                  {
                    key: (input) =>
                      input.buildings[buildingIndex].params.floorArea,
                    modelType: ModelType.NUMBER,
                    min: 0,
                    max: 100000,
                    step: 1,
                    unit: T.ectoplanner.units.m2,
                    label: T.ectoplanner.form.building.params.floorarea.label,
                    helpText:
                      T.ectoplanner.form.building.params.floorarea.helptext,
                    preventRecursiveOnDidUpdate: true,
                    onDidUpdate: (
                      _key: string[],
                      area: number,
                      form: EctoplannerForm
                    ) => {
                      const annDemTotHeat = calcAnnDemTot(
                        area,
                        form.buildings[buildingIndex].params.annDemSpecHeat
                      );
                      const peakDemTotHeat = calcPeakDemTot(
                        area,
                        form.buildings[buildingIndex].params.peakDemSpecHeat
                      );
                      const fullLoadHrsHeat = calcFullLoadHours(
                        peakDemTotHeat,
                        annDemTotHeat
                      );
                      const annDemTotCool = calcAnnDemTot(
                        area,
                        form.buildings[buildingIndex].params.annDemSpecCool
                      );

                      const peakDemTotCool = calcPeakDemTot(
                        area,
                        form.buildings[buildingIndex].params.peakDemSpecCool
                      );
                      const fullLoadHrsCool = calcFullLoadHours(
                        peakDemTotCool,
                        annDemTotCool
                      );

                      return [
                        [
                          (input) =>
                            input.buildings[buildingIndex].params.annDemTotHeat,
                          annDemTotHeat
                        ],
                        [
                          (input) =>
                            input.buildings[buildingIndex].params
                              .peakDemTotHeat,
                          peakDemTotHeat
                        ],
                        [
                          (input) =>
                            input.buildings[buildingIndex].params
                              .fullLoadHrsHeat,
                          fullLoadHrsHeat
                        ],
                        [
                          (input) =>
                            input.buildings[buildingIndex].params.annDemTotCool,
                          annDemTotCool
                        ],
                        [
                          (input) =>
                            input.buildings[buildingIndex].params
                              .peakDemTotCool,
                          peakDemTotCool
                        ],
                        [
                          (input) =>
                            input.buildings[buildingIndex].params
                              .fullLoadHrsCool,
                          fullLoadHrsCool
                        ],
                        [
                          (input) =>
                            input.buildings[buildingIndex].params
                              .annDemTotProcessCool,
                          calcAnnDemTot(
                            area,
                            form.buildings[buildingIndex].params
                              .annDemSpecProcessCool
                          )
                        ],
                        [
                          (input) =>
                            input.buildings[buildingIndex].params
                              .annDemTotPlugLoads,
                          calcAnnDemTot(
                            area,
                            form.buildings[buildingIndex].params
                              .annDemSpecPlugLoads
                          )
                        ],
                        [
                          (input) =>
                            input.buildings[buildingIndex].params.annDemTotDhw,
                          calcAnnDemTot(
                            area,
                            form.buildings[buildingIndex].params.annDemSpecDhw
                          )
                        ]
                      ];
                    }
                  },
                  {
                    key: (input) =>
                      input.buildings[buildingIndex].params.buildingType,
                    modelType: ModelType.OPTIONS,
                    options: buildingTypeOptions,
                    label:
                      T.ectoplanner.form.building.params.buildingtype.label,
                    helpText:
                      T.ectoplanner.form.building.params.buildingtype.helptext,
                    onDidUpdate: (_key: string[]) => {
                      return [
                        [
                          (input) =>
                            input.buildings[buildingIndex].params
                              .buildingSubtype,
                          null
                        ]
                      ];
                    }
                  },
                  {
                    key: (input) =>
                      input.buildings[buildingIndex].params.buildingSubtype,
                    modelType: ModelType.OPTIONS,
                    options: (_unused, form: EctoplannerForm) => {
                      const buildingType = _.get(
                        form,
                        'buildings[' + buildingIndex + '].params.buildingType'
                      );
                      return buildingSubtypeOptions[buildingType] ?? [];
                    },
                    hasError: (subType: string, form: EctoplannerForm) => {
                      const buildingType =
                        form.buildings[buildingIndex].params.buildingType;
                      if (cityData == null) {
                        return false; // Still loading
                      }
                      return (
                        buildingType == null ||
                        subType == null ||
                        cityData[buildingType][subType] == null
                      );
                    },
                    label:
                      T.ectoplanner.form.building.params.buildingsubtype.label,
                    onDidUpdate: (
                      _key: string[],
                      newSubtype: string,
                      form: EctoplannerForm
                    ) => {
                      const params = form.buildings[buildingIndex].params;

                      if (newSubtype == null) {
                        return [];
                      }

                      const data = _.get(
                        cityData,
                        params.buildingType + '.' + newSubtype
                      );
                      if (data == null) {
                        return [];
                      }

                      return _.map(
                        ectoplannerCityDataKeys.map((key) => {
                          return [
                            (input: EctoplannerForm) =>
                              input.buildings[buildingIndex].params[key],
                            data[key] ?? _.get(params, key)
                          ];
                        })
                      );
                    }
                  }
                ]
              },
              {
                label: T.ectoplanner.form.building.params.sections.heating,
                wrapContent: false,
                sections: [
                  {
                    wrapContent: false,
                    models: [
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params.calculations
                            .demandProfiles.spaceHeatProfile,
                        modelType: ModelType.CUSTOM,
                        render: (props, _model, form) => (
                          <EctoplannerDemandGraphs
                            {...props}
                            primaryGraph={
                              form.buildings[buildingIndex].params
                                .checkCalcSpaceHeat &&
                              form.buildings[buildingIndex].params.calculations
                                .demandProfiles.spaceHeatProfile
                            }
                            secondaryGraph={
                              form.buildings[buildingIndex].params
                                .checkCalcDhw &&
                              form.buildings[buildingIndex].params.calculations
                                .demandProfiles.dhwProfile
                            }
                            primaryColor={colors.primary1Color}
                            secondaryColor={colors.primary3Color}
                            primaryName={
                              T.ectoplanner.form.building.params.sections
                                .spaceheating
                            }
                            secondaryName={
                              T.ectoplanner.form.building.params.sections.dhw
                            }
                          />
                        ),
                        visible: (form: EctoplannerForm) =>
                          form.buildings[buildingIndex].params.checkCalcDhw ||
                          form.buildings[buildingIndex].params
                            .checkCalcSpaceHeat,
                        hasError: () => false
                      }
                    ]
                  },
                  {
                    label:
                      T.ectoplanner.form.building.params.sections.spaceheating,
                    wrapContent: false,
                    models: [
                      ...energyKindModels(
                        T.ectoplanner.form.building.params.sections
                          .spaceheating,
                        'Heat',
                        buildingIndex,
                        'checkCalcSpaceHeat',
                        'spaceHeatProfile'
                      ),
                      ...calculationPeakDemModels(
                        'Heat',
                        buildingIndex,
                        'checkCalcSpaceHeat',
                        'spaceHeatProfile'
                      ),
                      ...calculationSeasonModels(
                        'Heating',
                        buildingIndex,
                        (form: EctoplannerForm) =>
                          form.buildings[buildingIndex].params
                            .checkCalcSpaceHeat &&
                          form.buildings[buildingIndex].params
                            .spaceHeatProfile == null
                      )
                    ]
                  },
                  {
                    label: T.ectoplanner.form.building.params.sections.dhw,
                    wrapContent: false,
                    models: [
                      ...energyKindModels(
                        T.ectoplanner.form.building.params.sections.dhw,
                        'Dhw',
                        buildingIndex,
                        'checkCalcDhw',
                        'dhwProfile'
                      ),
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params.shareDhw,
                        modelType: ModelType.NUMBER,
                        min: 0,
                        max: 100000,
                        step: 1,
                        unit: T.ectoplanner.units.percent,
                        label:
                          T.ectoplanner.form.building.params.sharedhw.label,
                        visible: (form: EctoplannerForm) =>
                          form.buildings[buildingIndex].params.checkCalcDhw &&
                          !form.buildings[buildingIndex].params.dhwProfile,
                        preventRecursiveOnDidUpdate: true,
                        helpText:
                          T.ectoplanner.form.building.params.sharedhw.helptext,
                        onDidUpdate: (
                          _key: string[],
                          shareDhw: number,
                          form: EctoplannerForm
                        ) => {
                          // annDemTotDhw = shareDhw * (annDemTotDhw + annDemTotHeat)
                          // annDemTotDhw = shareDhw * annDemTotDhw + shareDhw * annDemTotHeat
                          // annDemTotDhw - shareDhw * annDemTotDhw = shareDhw * annDemTotHeat
                          // annDemTotDhw * (1 - shareDhw) = shareDhw * annDemTotHeat
                          // annDemTotDhw = (shareDhw * annDemTotHeat) / (1 - shareDhw)
                          const shareDevFrac = shareDhw / 100.0;
                          if (shareDevFrac >= 1.0) {
                            return [];
                          }

                          const annDemTotDhw =
                            (shareDevFrac *
                              form.buildings[buildingIndex].params
                                .annDemTotHeat) /
                            (1 - shareDevFrac);
                          const buildingFloorArea = floorArea(
                            form,
                            buildingIndex
                          );
                          const annDemSpecDhw =
                            buildingFloorArea === 0.0
                              ? 0.0
                              : (annDemTotDhw * 1000.0) / buildingFloorArea;

                          return [
                            [
                              (input) =>
                                input.buildings[buildingIndex].params
                                  .annDemTotDhw,
                              Math.round(annDemTotDhw)
                            ],
                            [
                              (input) =>
                                input.buildings[buildingIndex].params
                                  .annDemSpecDhw,
                              roundTwoDecimals(annDemSpecDhw)
                            ]
                          ];
                        }
                      }
                    ]
                  }
                ]
              },
              {
                label: T.ectoplanner.form.building.params.sections.cooling,
                wrapContent: false,
                sections: [
                  {
                    wrapContent: false,
                    models: [
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params.calculations
                            .demandProfiles.spaceCoolProfile,
                        modelType: ModelType.CUSTOM,
                        render: (_props, _model, form) => (
                          <EctoplannerDemandGraphs
                            primaryGraph={
                              form.buildings[buildingIndex].params
                                .checkCalcSpaceCool &&
                              form.buildings[buildingIndex].params.calculations
                                .demandProfiles.spaceCoolProfile
                            }
                            secondaryGraph={
                              form.buildings[buildingIndex].params
                                .checkCalcProcessCool &&
                              form.buildings[buildingIndex].params.calculations
                                .demandProfiles.processCoolProfile
                            }
                            primaryColor={colors.accent1Color}
                            secondaryColor={colors.accent3Color}
                            primaryName={
                              T.ectoplanner.form.building.params.sections
                                .spacecooling
                            }
                            secondaryName={
                              T.ectoplanner.form.building.params.sections
                                .processcool
                            }
                          />
                        ),
                        visible: (form: EctoplannerForm) =>
                          form.buildings[buildingIndex].params
                            .checkCalcSpaceCool ||
                          form.buildings[buildingIndex].params
                            .checkCalcProcessCool,
                        hasError: () => false
                      }
                    ]
                  },
                  {
                    label:
                      T.ectoplanner.form.building.params.sections.spacecooling,
                    wrapContent: false,
                    models: [
                      ...energyKindModels(
                        T.ectoplanner.form.building.params.sections
                          .spacecooling,
                        'Cool',
                        buildingIndex,
                        'checkCalcSpaceCool',
                        'spaceCoolProfile'
                      ),
                      ...calculationPeakDemModels(
                        'Cool',
                        buildingIndex,
                        'checkCalcSpaceCool',
                        'spaceCoolProfile'
                      ),
                      ...calculationSeasonModels(
                        'Cooling',
                        buildingIndex,
                        (form: EctoplannerForm) =>
                          form.buildings[buildingIndex].params
                            .checkCalcSpaceCool &&
                          form.buildings[buildingIndex].params
                            .spaceCoolProfile == null
                      )
                    ]
                  },
                  {
                    label:
                      T.ectoplanner.form.building.params.sections.processcool,
                    wrapContent: false,
                    models: [
                      ...energyKindModels(
                        T.ectoplanner.form.building.params.sections.processcool,
                        'ProcessCool',
                        buildingIndex,
                        'checkCalcProcessCool',
                        'processCoolProfile'
                      )
                    ]
                  }
                ]
              },
              {
                label: T.ectoplanner.form.building.params.sections.energysystem,
                wrapContent: false,
                models: [
                  {
                    key: (input) =>
                      input.buildings[buildingIndex].params.useTransversal,
                    label:
                      T.ectoplanner.form.building.params.transversal
                        .usetransversal.label,
                    helpText:
                      T.ectoplanner.form.building.params.transversal
                        .usetransversal.helptext,
                    modelType: ModelType.BOOL
                  }
                ],
                sections: [
                  {
                    label:
                      T.ectoplanner.form.building.params.energysystem.sections
                        .spaceheating,
                    wrapContent: false,
                    visible: (form: EctoplannerForm) =>
                      !form.buildings[buildingIndex].params.useTransversal &&
                      form.buildings[buildingIndex].params.checkCalcSpaceHeat,
                    models: [
                      ...besHeatpumpModels('SpaceHeat', buildingIndex),
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besLowExElHeaterEnabled,
                        modelType: ModelType.BOOL,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexelheaterenabled.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexelheaterenabled.helptext
                      },
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besLowExElHeaterMaxLoad,
                        modelType: ModelType.NUMBER,
                        ...numberPercentWithUnitOptions,
                        visible: (form: EctoplannerForm) =>
                          form.buildings[buildingIndex].params
                            .besLowExElHeaterEnabled,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexelheatermaxload.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexelheatermaxload.helptext
                      }
                    ]
                  },
                  {
                    label:
                      T.ectoplanner.form.building.params.energysystem.sections
                        .dhw,
                    wrapContent: false,
                    models: besHeatpumpModels('Dhw', buildingIndex),
                    visible: (form: EctoplannerForm) =>
                      !form.buildings[buildingIndex].params.useTransversal &&
                      form.buildings[buildingIndex].params.checkCalcDhw
                  },
                  {
                    label:
                      T.ectoplanner.form.building.params.energysystem.sections
                        .coolingsupply,
                    wrapContent: false,
                    visible: (form: EctoplannerForm) =>
                      !form.buildings[buildingIndex].params.useTransversal &&
                      (form.buildings[buildingIndex].params
                        .checkCalcProcessCool ||
                        form.buildings[buildingIndex].params
                          .checkCalcSpaceCool),
                    models: [
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .lowExCoolingOption,
                        label:
                          T.ectoplanner.form.building.params.lowexcoolingoption
                            .label,
                        helpText:
                          T.ectoplanner.form.building.params.lowexcoolingoption
                            .helptext,
                        modelType: ModelType.OPTIONS,
                        options: lowexCoolingOptions
                      },
                      ...besHeatpumpModels(
                        'Cool',
                        buildingIndex,
                        (form) =>
                          form.buildings[buildingIndex].params
                            .lowExCoolingOption === 'lowExCc'
                      ),
                      {
                        modelType: ModelType.BOOL,
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besLowExPeakChillerEnabled,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillerenabled.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillerenabled.helptext
                      },
                      {
                        modelType: ModelType.NUMBER,
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besLowExPeakChillerCop,
                        min: 0,
                        max: 100,
                        step: 1,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillercop.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillercop.helptext,
                        visible: (form: EctoplannerForm) =>
                          form.buildings[buildingIndex].params
                            .besLowExPeakChillerEnabled
                      },
                      {
                        modelType: ModelType.NUMBER,
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besLowExPeakChillerMaxLoad,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillermaxload.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillermaxload.helptext,
                        ...numberPercentWithUnitOptions,
                        visible: (form: EctoplannerForm) =>
                          form.buildings[buildingIndex].params
                            .besLowExPeakChillerEnabled
                      }
                    ]
                  },
                  {
                    label:
                      T.ectoplanner.form.building.params.sections.transversal,
                    wrapContent: false,
                    visible: (form: EctoplannerForm) =>
                      form.buildings[buildingIndex].params.useTransversal,
                    models: [
                      {
                        /** The peak heat electricity rod */
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalElHeaterEnabled,
                        modelType: ModelType.BOOL,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexelheaterenabled.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexelheaterenabled.helptext
                      },
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransersalElHeaterMaxLoad,
                        modelType: ModelType.NUMBER,
                        min: 0,
                        max: 100,
                        step: 1,
                        unit: T.ectoplanner.units.percent,
                        visible: (form) =>
                          form.buildings[buildingIndex].params
                            .besTransversalElHeaterEnabled,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexelheatermaxload.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexelheatermaxload.helptext
                      },
                      {
                        /** The peak cold chiller */
                        modelType: ModelType.BOOL,
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalPeakChillerEnabled,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillerenabled.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillerenabled.helptext
                      },
                      {
                        modelType: ModelType.NUMBER,
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalPeakChillerCop,
                        min: 0,
                        max: 100,
                        step: 1,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillercop.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillercop.helptext,
                        visible: (form) =>
                          form.buildings[buildingIndex].params
                            .besTransversalPeakChillerEnabled
                      },
                      {
                        modelType: ModelType.NUMBER,
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalPeakChillerMaxLoad,
                        label:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillermaxload.label,
                        helpText:
                          T.ectoplanner.form.building.params
                            .beslowexpeakchillermaxload.helptext,
                        min: 0,
                        max: 100,
                        step: 1,
                        unit: T.ectoplanner.units.percent,
                        visible: (form) =>
                          form.buildings[buildingIndex].params
                            .besTransversalPeakChillerEnabled
                      },

                      // The transversal hp cop method
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalHpCopType,
                        label: T.ectoplanner.form.building.params.cop.label,
                        helpText:
                          T.ectoplanner.form.building.params.energysystem
                            .copoptions.helptext,
                        modelType: ModelType.OPTIONS,
                        options: copOptions
                      },
                      // The transversal hp cop value if const method
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalHpConstCop,
                        modelType: ModelType.NUMBER,
                        min: 0,
                        max: 40,
                        step: 1,
                        visible: (form) =>
                          form.buildings[buildingIndex].params
                            .besTransversalHpCopType === 'const',
                        label:
                          T.ectoplanner.form.building.params.beslowexhpcop.label
                      },
                      // The transversal hp type  if product data
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalHpType,
                        label:
                          T.ectoplanner.form.building.params.productdata.label,
                        modelType: ModelType.OPTIONS,
                        options: heatPumpOptions,
                        hasError: (value: string, form: EctoplannerForm) => {
                          if (
                            form.buildings[buildingIndex].params
                              .besTransversalHpType !== 'productData'
                          ) {
                            return false;
                          }

                          return (
                            value == null ||
                            heatPumpOptions.find((x) => x.value === value) ==
                              null
                          );
                        },
                        visible: (form) =>
                          form.buildings[buildingIndex].params
                            .besTransversalHpCopType === 'productData'
                      },
                      // The carnot efficiency
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalHpCarnotEta,
                        label:
                          T.ectoplanner.form.building.params.carnoteta.label,
                        helpText:
                          T.ectoplanner.form.building.params.carnotetahp
                            .helptext,
                        modelType: ModelType.NUMBER,
                        min: 0,
                        max: 100,
                        step: 1,
                        unit: T.ectoplanner.units.percent,
                        visible: (form) =>
                          form.buildings[buildingIndex].params
                            .besTransversalHpCopType === 'carnot'
                      },
                      // The supply temperature of space heating
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalSpaceHeatSupplyTemp,
                        label:
                          T.ectoplanner.form.building.params.supplytempheatpump
                            .label,
                        modelType: ModelType.NUMBER,
                        min: 0,
                        max: 80,
                        step: 1,
                        unit: T.ectoplanner.units.degc,
                        // useTooltipHelpTexts: false, // Need to show the COP calculation inline.
                        visible: (form) =>
                          form.buildings[buildingIndex].params
                            .besTransversalHpCopType === 'productData' ||
                          form.buildings[buildingIndex].params
                            .besTransversalHpCopType === 'carnot'
                      },
                      // Enable temperature sliding
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalSpaceHeatSupplyTempSliding,
                        label:
                          T.ectoplanner.form.building.params.considercurve
                            .label,
                        helpText:
                          T.ectoplanner.form.building.params.considercurve
                            .helptext,
                        modelType: ModelType.BOOL,
                        visible: (form) =>
                          form.buildings[buildingIndex].params
                            .besTransversalHpCopType === 'productData' ||
                          form.buildings[buildingIndex].params
                            .besTransversalHpCopType === 'carnot'
                      },
                      // The supply temperature of dhw
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalDhwSupplyTemp,
                        label:
                          T.ectoplanner.form.building.params.transversal
                            .supplytempdwh,
                        helpText:
                          T.ectoplanner.form.building.params.supplytempdhw
                            .helptext,
                        modelType: ModelType.NUMBER,
                        min: 0,
                        max: 80,
                        step: 1,
                        unit: T.ectoplanner.units.degc,
                        // useTooltipHelpTexts: false, // Need to show the COP calculation inline.
                        visible: (form) =>
                          (form.buildings[buildingIndex].params
                            .besTransversalHpCopType === 'productData' ||
                            form.buildings[buildingIndex].params
                              .besTransversalHpCopType === 'carnot') &&
                          form.buildings[buildingIndex].params.checkCalcDhw
                      },
                      // The supply temperature of cold water
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .besTransversalCoolSupplyTemp,
                        label:
                          T.ectoplanner.form.building.params.transversal
                            .coolsupplytemp,
                        helpText:
                          T.ectoplanner.form.building.params.supplytempchiller
                            .helptext,
                        modelType: ModelType.NUMBER,
                        min: 0,
                        max: 80,
                        step: 1,
                        unit: T.ectoplanner.units.degc,
                        // useTooltipHelpTexts: false, // Need to show the COP calculation inline.
                        visible: (form) =>
                          (form.buildings[buildingIndex].params
                            .besTransversalHpCopType === 'productData' ||
                            form.buildings[buildingIndex].params
                              .besTransversalHpCopType === 'carnot') &&
                          (form.buildings[buildingIndex].params
                            .checkCalcProcessCool ||
                            form.buildings[buildingIndex].params
                              .checkCalcSpaceCool)
                      }
                    ]
                  }
                ]
              },
              {
                label: T.ectoplanner.form.building.params.sections.investment,
                wrapContent: false,
                sections: [
                  {
                    label:
                      T.ectoplanner.form.building.params.sections.investment,
                    wrapContent: false,
                    models: [
                      {
                        key: (input) =>
                          input.buildings[buildingIndex].params
                            .individual_costs,
                        modelType: ModelType.BOOL,
                        label:
                          T.ectoplanner.form.building.params.individualcosts
                            .label,
                        helpText:
                          T.ectoplanner.form.building.params.individualcosts
                            .helptext
                      }
                    ]
                  },
                  {
                    label: T.ectoplanner.form.investment.hp.label,
                    wrapContent: false,
                    models: buildingInvestmentModels(buildingIndex, 'hp'),
                    visible: (form: EctoplannerForm) =>
                      form.buildings[buildingIndex].params.individual_costs &&
                      (form.buildings[buildingIndex].params
                        .checkCalcSpaceHeat ||
                        form.buildings[buildingIndex].params.checkCalcDhw) &&
                      !form.buildings[buildingIndex].params.useTransversal
                  },
                  {
                    label: T.ectoplanner.form.investment.eh.label,
                    wrapContent: false,
                    models: buildingInvestmentModels(buildingIndex, 'eh'),
                    visible: (form: EctoplannerForm) =>
                      form.buildings[buildingIndex].params.individual_costs &&
                      (form.buildings[buildingIndex].params
                        .checkCalcSpaceHeat ||
                        form.buildings[buildingIndex].params.checkCalcDhw) &&
                      form.buildings[buildingIndex].params
                        .besLowExElHeaterEnabled &&
                      !form.buildings[buildingIndex].params.useTransversal
                  },
                  {
                    label: T.ectoplanner.form.investment.cc.label,
                    wrapContent: false,
                    models: buildingInvestmentModels(buildingIndex, 'cc'),
                    visible: (form: EctoplannerForm) =>
                      form.buildings[buildingIndex].params.individual_costs &&
                      (form.buildings[buildingIndex].params
                        .checkCalcSpaceCool ||
                        form.buildings[buildingIndex].params
                          .checkCalcProcessCool) &&
                      form.buildings[buildingIndex].params
                        .lowExCoolingOption === 'lowExCc' &&
                      !form.buildings[buildingIndex].params.useTransversal
                  },
                  {
                    label: T.ectoplanner.form.investment.peak_chiller.label,
                    wrapContent: false,
                    models: buildingInvestmentModels(
                      buildingIndex,
                      'peak_chiller'
                    ),
                    visible: (form: EctoplannerForm) =>
                      form.buildings[buildingIndex].params.individual_costs &&
                      (form.buildings[buildingIndex].params
                        .checkCalcSpaceCool ||
                        form.buildings[buildingIndex].params
                          .checkCalcProcessCool) &&
                      form.buildings[buildingIndex].params
                        .besLowExPeakChillerEnabled &&
                      !form.buildings[buildingIndex].params.useTransversal
                  },
                  {
                    label: T.ectoplanner.form.investment.drc.label,
                    wrapContent: false,
                    models: buildingInvestmentModels(buildingIndex, 'drc'),
                    visible: (form: EctoplannerForm) =>
                      form.buildings[buildingIndex].params.individual_costs &&
                      (form.buildings[buildingIndex].params
                        .checkCalcSpaceCool ||
                        form.buildings[buildingIndex].params
                          .checkCalcProcessCool) &&
                      form.buildings[buildingIndex].params
                        .lowExCoolingOption === 'lowExDrc' &&
                      !form.buildings[buildingIndex].params.useTransversal
                  },
                  {
                    label: T.ectoplanner.form.shared.transversalhp,
                    wrapContent: false,
                    models: buildingInvestmentModels(
                      buildingIndex,
                      'transversal_hp'
                    ),
                    visible: (form: EctoplannerForm) =>
                      form.buildings[buildingIndex].params.individual_costs &&
                      (form.buildings[buildingIndex].params
                        .checkCalcSpaceHeat ||
                        form.buildings[buildingIndex].params.checkCalcDhw) &&
                      form.buildings[buildingIndex].params.useTransversal
                  },
                  {
                    label:
                      T.ectoplanner.form.building.params.beslowexelheaterenabled
                        .label,
                    wrapContent: false,
                    models: buildingInvestmentModels(
                      buildingIndex,
                      'transversal_hp_eh'
                    ),
                    visible: (form: EctoplannerForm) =>
                      form.buildings[buildingIndex].params.individual_costs &&
                      (form.buildings[buildingIndex].params
                        .checkCalcSpaceHeat ||
                        form.buildings[buildingIndex].params.checkCalcDhw) &&
                      form.buildings[buildingIndex].params.useTransversal &&
                      form.buildings[buildingIndex].params
                        .besTransversalElHeaterEnabled
                  },
                  {
                    label:
                      T.ectoplanner.form.building.params
                        .beslowexpeakchillerenabled.label,
                    wrapContent: false,
                    models: buildingInvestmentModels(
                      buildingIndex,
                      'transversal_hp_peak_chiller'
                    ),
                    visible: (form: EctoplannerForm) =>
                      form.buildings[buildingIndex].params.individual_costs &&
                      (form.buildings[buildingIndex].params
                        .checkCalcSpaceHeat ||
                        form.buildings[buildingIndex].params.checkCalcDhw) &&
                      form.buildings[buildingIndex].params.useTransversal &&
                      form.buildings[buildingIndex].params
                        .besTransversalPeakChillerEnabled
                  }
                ]
              }
            ]
          }
        ],
        buildingHorizontalWeights
      )
  );

  return _.flatMap(allSections);
};

const buildingInvestmentModels = (
  index: number,
  prefix:
    | 'hp'
    | 'eh'
    | 'cc'
    | 'drc'
    | 'peak_chiller'
    | 'transversal_hp'
    | 'transversal_hp_eh'
    | 'transversal_hp_peak_chiller'
): EctoplannerModelDefinition[] => {
  return [
    {
      key: (input) =>
        input.buildings[index].params[makeKey(prefix, '_inv_var')],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 1000000000,
      step: 1,
      unit: T.ectoplanner.units.eurkw,
      label: T.ectoplanner.form.shared.invvar.label,
      helpText: T.ectoplanner.form.shared.invvar.helptext,
      visible: (form: EctoplannerForm) =>
        form.buildings[index].params.individual_costs
    },
    {
      key: (input) =>
        input.buildings[index].params[makeKey(prefix, '_life_time')],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 1000000000,
      step: 1,
      unit: T.ectoplanner.units.years,
      label: T.ectoplanner.form.shared.lifetime.label,
      helpText: T.ectoplanner.form.shared.lifetime.helptext,
      visible: (form: EctoplannerForm) =>
        form.buildings[index].params.individual_costs
    },
    {
      key: (input) => input.buildings[index].params[makeKey(prefix, '_om')],
      modelType: ModelType.NUMBER,
      min: 0,
      max: 1000000000,
      step: 1,
      unit: T.ectoplanner.units.percent,
      label: T.ectoplanner.form.shared.costom.label,
      visible: (form: EctoplannerForm) =>
        form.buildings[index].params.individual_costs
    }
  ];
};
